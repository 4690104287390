import { UserService } from './../services/user.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private userService:UserService,private router:Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let role = this.userService.getRole();
    if(role!='NO_ACCESS_TOKEN'){
      if(route.data[role]){
        if(!route.data[role].isAvailable){
          if(route.data[role].redirect.isRedirect){
            console.log(this.router);
            this.router.navigate([route.data[role].redirect.uri]);
          }
          else return false;
        }
        else return true;
      }
      else{
        return false;
      }
    }
  }
  
}
