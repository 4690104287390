import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { districtTimeZone } from '../app.config';
@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor() { }
  private roleBasedActions={
    "assigned": {
      "FRCC": {
        "past": {
          "1": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": true,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "2": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": true,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "3": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": true,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "4": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": true,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "5": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": true,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "6": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": true,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "7": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": true,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "8": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": true,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          }
        },
        "current": {
          "1": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": true,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "2": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": true,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "3": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": true,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "4": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": true,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "5": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": true,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "6": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": true,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "7": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": true,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "8": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": true,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          }
        },
        "future": {
          "1": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "2": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "3": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "4": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "5": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "6": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "7": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "8": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          }
        }
      },
      "AW_SUPERVISOR": {
        "past": {
          "1": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "2": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "3": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "4": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "5": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "6": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "7": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "8": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          }
        },
        "current": {
          "1": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "2": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "3": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "4": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "5": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "6": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "7": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "8": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          }
        },
        "future": {
          "1": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "2": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "3": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "4": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "5": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "6": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "7": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "8": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          }
        }
      },
      "CONT_SUPERVISOR": {
        "past": {
          "1": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "2": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "3": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "4": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "5": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "6": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "7": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "8": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": true,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          }
        },
        "current": {
          "1": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "2": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "3": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": true
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "4": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "5": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "6": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "7": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "8": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          }
        },
        "future": {
          "1": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "2": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "3": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": true,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "4": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "5": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "6": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "7": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "8": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": true,
              "u": true,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          }
        }
      }
    },
    "unassigned": {
      "FRCC": {
        "valid": {
          "4": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "5": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "6": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "7": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "8": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          }
        },
        "invalid": {
          "4": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "5": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "6": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "7": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "8": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          }
        },
        "unassigned": {
          "1": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "2": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "3": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          }
        }
      },
      "AW_SUPERVISOR": {
        "valid": {
          "4": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "5": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "6": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "7": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "8": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          }
        },
        "invalid": {
          "4": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "5": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "6": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "7": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "8": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          }
        },
        "unassigned": {
          "1": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "2": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "3": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          }
        }
      },
      "CONT_SUPERVISOR": {
        "valid": {
          "4": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": true
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "5": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": true
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "6": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": true
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "7": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": true
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "8": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": true
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          }
        },
        "invalid": {
          "4": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": true
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "5": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": true
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "6": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": true
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "7": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": true
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "8": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": true
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          }
        },
        "unassigned": {
          "1": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "2": {
            "UNSC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          },
          "3": {
            "UNSC": {
              "r": true,
              "u": false,
              "h": false,
              "s": true
            },
            "ASGN": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ACPT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DECL": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PRDS": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "DISP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "AKNW": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "HOLD": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ENRT": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "ONST": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "COMP": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "INCM": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "PECA": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            },
            "CANC": {
              "r": false,
              "u": false,
              "h": false,
              "s": false
            }
          }
        }
      }
    }
  }
  getActions(role,assignimentDate,priority,status,earlyStartDate,district){
    role = role=="ADMIN"?"FRCC":role;
    const timeZone = districtTimeZone[district];
    let currentDate = timeZone ? moment.tz(new Date(), timeZone).format("YYYY-MM-DD") : moment();
    
    if(status=='UNSC'){
      let earlyStartType = earlyStartDate?(moment(earlyStartDate).isSame(currentDate, 'day')?'current':(moment(earlyStartDate).isAfter(currentDate, 'day')?'future':'past')):null
      let seniario = priority<=3?'unassigned':(!earlyStartType||earlyStartType=='future'?'invalid':'valid');
      console.log('unscConf:',role,seniario,priority,status);
      return this.roleBasedActions.unassigned[role][seniario][priority][status]
    }
    else{
      let assignimentType = assignimentDate?(moment(assignimentDate).isSame(currentDate, 'day')?'current':(moment(assignimentDate).isAfter(currentDate, 'day')?'future':'past')):null
      console.log('assigniedConf:',role,assignimentType,priority,status);
      return this.roleBasedActions.assigned[role][assignimentType][priority][status]
    }
  }
}
