import { Injectable } from '@angular/core';
import { API_URL } from './../app.config';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private httpService:HttpService) { }

  getAllLinks(){
    let url = API_URL.getAllAppLinks;
    return this.httpService.apiRequest(url, 'GET');
  }
  createLink(data){
    let url = API_URL.createAppLink;
    return this.httpService.apiRequest(url, 'POST',data);
  }
  updateLink(data){
    let url = API_URL.updateAppLink;
    return this.httpService.apiRequest(url, 'PUT',data);
  }
  deleteLink(id){
    let url = API_URL.deleteAppLink.replace("{id}",id);
    return this.httpService.apiRequest(url, 'DELETE');
  }
  getLocalitiesAndZipCodesForDistrict(state,district){
    let url=API_URL.getLocalitiesAndZipCodesForDistrict.replace("{state}",state).replace("{district}",district);
    return this.httpService.apiRequest(url, 'GET'); 
  }
  addLocalitiesForDistrict(district,data){
    let url=API_URL.addLocalitiesForDistrict.replace("{district}",district);
    return this.httpService.apiRequest(url, 'POST',data); 
  }
  addZipCodesForDistrict(district,data){
    let url=API_URL.addZipCodesForDistrict.replace("{district}",district);
    return this.httpService.apiRequest(url, 'POST',data); 
  }
  getAllMatCodesData(){
    let url = API_URL.getAllMatCodesData;
    return this.httpService.apiRequest(url, 'GET');
  }
  addMatCodeData(data){
    let url = API_URL.getAllMatCodesData;
    return this.httpService.apiRequest(url, 'PUT',data);
  }
}
