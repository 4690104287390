import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<AlertsComponent>) { }

  ngOnInit() {
  }
  onDiaglogAction(action){
    let data = false;
    switch(action){
      case 'ok':
        data= true; 
      break;
      case 'cancel':
        data = false;
        break
    }
    this.dialogRef.close(data);
  }
  close(){
    this.dialogRef.close();
  }

}
