<!--header-->
<div mat-dialog-title>
    <!--title-->
    <h2 class="title">
        Schedule Orders
    </h2>
    <!--title-->
   
</div>
<div mat-dialog-content class="content">
     <!--date Picker-->
     <div class="datepicker-container">
        <button class="datepicker-btn" mat-icon-button [disabled]="datepicker.buttonDisable.left" (click)="onPickerChanges('decrement')" >
            <i class="material-icons">
                chevron_left
            </i>
        </button>
        <div class="datepicker-content">
            <div *ngFor="let item of datepicker.data let i = index" [ngClass]="{'selected':item.date == selectedData.date.date}" (click)="onDateChange(item)" class="date-container">
                <div class="date-content">
                   <span>{{item.day}}</span>
                   <h4>{{item.date}}</h4> 
                </div>
                <p class="order-content">{{item.orders.length}} Scheduled</p>
            </div>
        </div>
        <button class="datepicker-btn" mat-icon-button [disabled]="datepicker.buttonDisable.right" (click)="onPickerChanges('increment')">
            <i class="material-icons">
                chevron_right
            </i>
        </button>
    </div>
    <!--date Picker-->
    <!--slots-->
    <div class="slots">
    <mat-radio-group [(ngModel)]="selectedData.slot" >
        
        <mat-radio-button  *ngFor="let item of allSlots"  [disabled]="item.slotId!=0?(isCurrentDate):false" [value]="item" class="dark-radio-buttons slot">
            {{item.slotId==0?"All Day":moment(item.startTime,"HH:mm").format("hh:mm A") + " - " + moment(item.endTime,"HH:mm").format("hh:mm A")}}
        </mat-radio-button>
    
    </mat-radio-group>
    
</div>
    <!--slots-->
    <!--tabs-->
    <div class="tab-container">
        <div class="tab" [ngClass]="{'selected':selectedData.tab==1}" (click)="selectedData.tab=1">
            Selected Orders({{inputData.selectedOrders.length}})
        </div>
        <div class="tab" [ngClass]="{'selected':selectedData.tab==2}" (click)="selectedData.tab=2">
            Scheduled Orders({{selectedData.date?.orders.length}})
        </div>
    </div>
    <!--tabs-->
    <div class="table-container">
    <app-orders-grid  [orders]="selectedData.tab==1?inputData.selectedOrders:selectedData.date.orders"  class="orders-grid-component" currentTab="scheduleModel"  [engineerModal]=true></app-orders-grid>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button  class="action-btn cancel"  mat-button mat-dialog-close>Cancel</button>
    <button (click)="onSchedule()" class="action-btn schedule" mat-button >Schedule</button>
</div>