<mat-toolbar color='primary'>  
    <img class='app-icon' src="assets/W1V_Management_Logo@1x.svg">
      
<div class="nav-bar">
    <ng-container *ngFor='let link of links|keyvalue:retrunZero'>
    <a  *ngIf="roleAccessMapping[link.key][role]?.isAvailable" [routerLink]="link.value['url']" routerLinkActive="active" class="link"  [ngClass]="link.value['lable']"  > 
       <span [matBadge]="link.value['notificationCount']"[matBadgeHidden]="link.value['notificationCount']==0" matBadgeColor="warn"  matBadgeOverlap="false"  class="notification-icon"></span>
        <img   class="icon" [src]='link.value["icon"]'>
        <div class="label">{{link.value["lable"]}}</div>
        
    </a>
    </ng-container>
    <a  *ngIf="isSupportRole" [routerLink]="['/support']" routerLinkActive="active" class="link" > 
        <span matBadgeColor="warn"  matBadgeOverlap="false"  class="notification-icon"></span>
         <img   class="icon" src = "assets/support-icon.svg">
         <div class="label">Support</div>
         
     </a>
</div>
<button mat-button (click)="onOpenSearch()" class="searchbox"><i class="material-icons"> search</i> Find WO</button>
<img [matMenuTriggerFor]="userOptions" (menuOpened)="menuOpened()" class="user-avatar" src="assets/worker-512.jpg" >
<mat-menu #userOptions="matMenu"   [overlapTrigger]="false"  yPosition="below">
    <div class="user-menu">
    <h1 class="title-text user-name" >{{userName}}</h1>
    
    <button (click)="loadUserPreferences()" class="normal-link" mat-menu-item><img src="../assets/setting.svg" >Settings</button>
    <button (click)="loadLegend()" class="normal-link"  mat-menu-item><img src="../assets/legend.svg" />Legend</button>
    <button (click)="loadMiuvalidator()" class="normal-link"  mat-menu-item><i class="material-icons">check</i>Validator Tool</button>
    
    <mat-progress-bar *ngIf="externalLinkLoader" mode="indeterminate" color="accent"></mat-progress-bar>
    <div class="links-section">
    <button class="normal-link" mat-menu-item *ngFor="let app of appLinks" (click)="openInNextTab(app['link'])"><i class="material-icons">open_in_new</i> {{app['title']}}</button>
    </div>
    <button (click)='logout()' class="signout" mat-menu-item color="accent"><i class="material-icons">logout</i>Sign-Out</button>
    <h6 class="version">Version : {{version}}</h6>
</div>
  </mat-menu>
    
</mat-toolbar>
<app-banner ></app-banner>