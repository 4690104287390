import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';

@Component({
  selector: 'app-orders-auditlog',
  templateUrl: './orders-auditlog.component.html',
  styleUrls: ['./orders-auditlog.component.scss']
})
export class OrdersAuditlogComponent implements OnInit {
  displayedColumns: string[] = ['updateTime','userId', 'status','startTime','finishTime','assigned','oldReason'];
  dataSource = new MatTableDataSource();
  moment = moment;
    constructor(@Inject(MAT_DIALOG_DATA) public inputData, public dialogRef: MatDialogRef<OrdersAuditlogComponent>) { }
    ngOnInit(): void {
      this.dataSource.data = this.inputData.workOrderLogDetailsList
    }
}
