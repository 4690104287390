<div class="mat-dialog-title">
  <h1>Recommend</h1>
  <div class="work-orders">Work Orders: 
    
    <b *ngFor="let order of inputData.orders" [ngClass]="{'police-escort':order['policeEscort']=='Yes'}" > {{order['workOrderNumber']}}</b> 
    
  </div>

  <div class="time-picker">
   
      
      <mat-form-field class="date-input dark-input without-error" appearance="outline">
        <mat-label>Start Date</mat-label>
        <input matInput [readonly]="true" [matDatepicker]="picker"  [(ngModel)]="dateConfig.selectedDate" [max]="dateConfig.maxDate" [min]="dateConfig.minDate">
        <mat-datepicker-toggle matSuffix  [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    
    
    
     
        <!-- <ngx-mat-timepicker-field [format]="24" color="accent" [defaultTime]="dateConfig.startTime"  class="custom-time-picker" (timeChanged)="onTimeChange($event)" controlOnly="true"></ngx-mat-timepicker-field> -->
        <mat-form-field appearance="outline" class="dark-input custom-time-picker without-error" >
          <mat-label>Start time</mat-label>
          <input matInput  type="time" [value]="dateConfig.startTime"  (change)="onTimeChange($event.srcElement.value)">
        </mat-form-field>
      
      </div>
  <button mat-icon-button class="close-button" mat-dialog-close>
      <mat-icon class="close-icon" color="primary">close</mat-icon>
  </button>
</div>
<!-- <div *ngIf="showError">Select a time and FSR to dispatch the order(s)</div> -->
<!--Mat Tabs-->
<mat-tab-group color="accent" class="employee-tab" mat-align-tabs="start">
  <mat-tab *ngFor="let item of inputData.fsr|keyvalue" [label]="mapping[item.key]">
    <div class="search">
      <input matInput type="search" placeholder="Search" class="search-input" (keyup)="applyFilter($event,item.value,'name')" >
      <mat-icon>search</mat-icon>
    </div>
    <div class="employees-list">
      <ng-container *ngFor="let fsr of item.value" >
        <ng-template *ngTemplateOutlet="recommendFsr; context: {$implicit: fsr,tab:mapping[item.key]}"></ng-template> 
      </ng-container>
    </div>
  </mat-tab>
  <!-- <mat-tab label="Supported">
    <div class="search">
      <input matInput type="search" placeholder="Search" class="search-input" (keyup)="applyFilter($event,inputData.fsr.supportedFsr,'name')" >
      <mat-icon>search</mat-icon>
    </div>
    <div class="employees-list">
      <ng-container *ngFor="let fsr of inputData.fsr.supportedFsr" >
        <ng-template *ngTemplateOutlet="recommendFsr; context: {$implicit: fsr}"></ng-template> 
      </ng-container>
    </div>
  </mat-tab> -->
</mat-tab-group>
<!--Fsr List-->
<ng-template #employeeList  #recommendFsr let-fsr let-tabdata = "tab" >
  <div [ngStyle]= "{'display': !fsr.hide  ? 'flex' : 'none' }" (click)="fsrRecommended = inputData.selectedFsrId == fsr['personnelNumber'] ? null : fsrRecommended && fsr['personnelNumber'] == fsrRecommended['personnelNumber'] ? null : fsr"  class="custom-mat-option large-checkbox" >
    <div class="employee-detail" [ngClass]=" inputData.selectedFsrId == fsr['personnelNumber'] ? 'disable' : fsrRecommended && fsrRecommended['personnelNumber'] == fsr.personnelNumber ? 'active': ''">
        <img src="assets/worker-512.jpg" />
        <span class="employee-name">{{fsr.name}}</span>
        <span>({{tabdata=="Contractors"?fsr.companyName:fsr.personnelNumber}})</span>
    </div>
  </div>
</ng-template>
<!-- {{fsrRecommended}} -->
<!--Action buttons-->
<mat-dialog-actions align="end">
  <button mat-stroked-button color="primary" class="bottom-btn" mat-dialog-close>Cancel</button>
  <button mat-flat-button color="accent" (click)=recommendOrder() class="bottom-btn">Recommend</button>
</mat-dialog-actions>