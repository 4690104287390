import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { uniq } from 'lodash-es';
import cloneDeep from 'lodash-es/cloneDeep';
import * as moment from 'moment';
import { Subject, forkJoin, interval, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AlertsComponent } from 'src/app/components/alerts/alerts.component';
import { AppointmentModalComponent } from 'src/app/components/appointment-modal/appointment-modal.component';
import { RecommendModalComponent } from 'src/app/components/recommend-modal/recommend-modal.component';
import { LoaderService } from 'src/app/services/loader.service';
import { OrdersService, Unschedule } from 'src/app/services/orders.service';
import { SearchService } from 'src/app/services/search.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  inputData = {
    orders: [],
    engineerData: []
  }
  searchValue: any;
  workOrderSelect: any;
  criteria = {r:false,u:false,h:false,s:false};
  checkSearch: boolean;
  reloadSearchApi: boolean;
  timer = {
    interval: 30,
    intervalSubscription: new Subject(),
    isActive: true
  }
  userRole
  private pendingStopPolling = new Subject();
  subscribers: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data,private matDialog: MatDialog, private ordersService: OrdersService, private toastService: ToastService, private loader: LoaderService,private searchService:SearchService,private userService:UserService) { }

  ngOnInit(): void {
    this.userRole = this.userService.getRole();
  }
  close(){
    this.matDialog.closeAll()
  }
  reloadData(searchValue?) {
    //console.log("RESLOAOD");
    if (searchValue) {
      this.reloadSearchApi = true
      this.loadComponentsData();
    } else {
      this.reloadSearchApi = false
      this.loadComponentsData();
    }
  }
  onApplyFilter(filterValue) {

    const reg = new RegExp('^[0-9]+$');
    if (filterValue != null && (filterValue.length < 9) && filterValue.length > 0) {
      this.toastService.error("Please enter work order number of minimum 9 digits to search");
      return;
    }
    else if (filterValue == null || filterValue.length == 0) {
      this.ordersService.searchValue = null
      this.reloadData();
    }
    else if (reg.test(filterValue) == false) {
      this.toastService.error("Enter only numeric didgits to search");
      return;
    }
    else if (filterValue != null && filterValue.length >= 9) {
      this.loader.showLoader();
      this.checkSearch = true;
      this.ordersService.searchValue = filterValue;
      this.workOrderSelect = []

      this.reloadData(filterValue)

    }
  }
  /**
   * UnSchedule workorders API call
   * @param unscOrdersList -list of orders to pass for unscOrdersList
   */
  unschedule(orders) {
    let unscheduleErrorOrder = {order:[],status:[]};
  let workOrderNumber = [];
  let pinError = [];
  let alertObject;
   orders.map(item=>{
       if(item.pinnedFlag){
      pinError.push(item.workOrderNumber)
    }
    workOrderNumber.push(item.workOrderNumber)
  });
  if(pinError.length === 0){

    if (unscheduleErrorOrder.order.length === 0) {
      alertObject = {
        type: "confirm",
        title: "Unschedule Orders? ",
        message: `Work Orders.(${this.workOrderSelect.length}) : '${workOrderNumber}'`,
        buttonPositiveText:"Unschedule"
      };
    } else {
      alertObject = {
        type: "alert",
        title: "Unschedule Error",
        message:   `Work Orders.('${unscheduleErrorOrder.order}') with '${uniq(unscheduleErrorOrder.status)}' can't be Unscheduled`,
      };
    }
    const dialog = this.matDialog.open(AlertsComponent, {
      data: alertObject,
      hasBackdrop: true,
      disableClose: true,
      minWidth: "300px",
      maxWidth: "32vw",
    });
    if(unscheduleErrorOrder.order.length === 0){
      dialog.afterClosed().subscribe((result) => {
        if (result) {
          //console.log(unscOrdersList,"unscOrdersList");
          this.unscheduleOrders(orders)
        }
      });
    }
  }
  else{
    this.toastService.error(`Pinned Work Orders(${pinError}) cannot be recommended or Unscheduled`);
  }
  }

  unscheduleOrders(orders){
    this.loader.showLoader();
    let unscOrdersList = [];
    let accessToken = JSON.parse(window.localStorage["okta-token-storage"]);
    orders.forEach((item) => {
      let unscobject: Unschedule = {
        assignedEngineer: "",
        assignmentEnd: moment(item.assignmentEnd).format("YYYY-MM-DDHH:mm:ss"),
        assignmentStart: moment(item.assignmentStart).format("YYYY-MM-DDHH:mm:ss"),
        dispatchId: accessToken.idToken.claims.preferred_username.split("@")[0],
        engineerId: "",
        itemTimeStamp: moment().format("YYYY-MM-DDHH:mm:ss"),
        operationNumber: "10",
        workOrderNumber: item.workOrderNumber,
        statusNonNumber: "",
        statusNotes: "",
        statusNumber: "UNSC",
      };
      unscOrdersList.push(unscobject);
    });
    this.ordersService.unscheduleWorkOrders(unscOrdersList).subscribe((response)=>{
      this.loader.hideLoader();
      let success = []
      let failed = []
      for( let key in response) {
         console.log(key);
        if (response[key]) {
          success.push(key);
        } else {
          failed.push(key);
        }
      };
      if(success.length > 0){
        this.loadComponentsData();
        this.toastService.success(`Work Orders.('${success}') unscheduled successfully`);
        this.ordersService.addselectedOrders(this.workOrderSelect)
      }
      if(failed.length > 0){
        this.toastService.error(`Work Orders.('${failed}') failed to unscheduled`);
      }
    },(error)=>{
      this.loader.hideLoader();
      this.toastService.error("There was an error Please try again later");
    });
  }
  /**
   * Open recommend Modal
   */
  openRecommend(orders) {
    this.workOrderSelect = orders;
    if (this.workOrderSelect.length > 1) {
      let unique = [...new Set(this.workOrderSelect.map(propYoureChecking => propYoureChecking.district))];
      if (unique.length === 1) {
        this.openRecommendModal(this.workOrderSelect[0].district);
      } else {
        const alertObject = {
          type: 'alert',
          title: 'Recommend Error',
          message: "You have selected workorders for more than one state/district. Only a single district is permitted to dispatch bulk orders.",

        };
        this.matDialog.open(AlertsComponent, { data: alertObject, hasBackdrop: true, disableClose: true, maxWidth: "32vw" });
      }
    } else {
      if (this.ordersService.recommendStatusCheck.indexOf(this.workOrderSelect[0].status) !== -1) {
        this.openRecommendModal(this.workOrderSelect[0].district);
      } else {
        this.toastService.error("Cant recommend " + this.workOrderSelect[0].status + " orders");
      }
    }
  }
  openRecommendModal(district) {
    this.loader.showLoader();
    this.ordersService.getRecommendEmployees(district).subscribe((response) => {
      this.loader.hideLoader();
      const dialog = this.matDialog.open(RecommendModalComponent, {
        maxHeight: '90vh',
        maxWidth: '100%',
        data: { fsr: response, orders: this.workOrderSelect }
      });
      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this.loadComponentsData();
          this.workOrderSelect = [];
        }
      });

    }, (error) => {
      this.loader.hideLoader();
      this.toastService.error("There was an error please try again later");
      console.log("Error from server", error);
    });
  }
  restrictAlphabets(e) {
    var x = e.which || e.keycode;

    if ((x >= 48 && x <= 57)) {
      return true;
    }
    else if (e.which == 13) {
      this.onApplyFilter(this.searchValue);
    }
    else {
      return false;
    }
  }
  onTimerChange(event) {
    // console.log(event.checked);
    if (event.checked) {
      this.timer.isActive = true;
      this.loadComponentsData();
    }
    else {
      this.timer.isActive = false;
      this.timer.intervalSubscription.next(true);

      this.pendingStopPolling.next(true);

    }
  }
  loadComponentsData(clearLocal?) {
    this.loader.showLoader();
    // debugger;
    if (!clearLocal) {
      localStorage.removeItem("pendingOrderSelected");
      localStorage.removeItem("pagination");
    }
    if (this.timer.isActive) {
      this.pendingStopPolling.next(true);
    }
    this.subscribers = this.timer.isActive ? timer(1, 30000).pipe(switchMap(() => this.ordersService.getWorkOrdersOnSearch(this.ordersService.searchValue)),
      takeUntil(this.pendingStopPolling)) : this.ordersService.getWorkOrdersOnSearch(this.ordersService.searchValue);
      this.subscribers.subscribe((response) => {
      
      if (this.timer.isActive) {
        this.timer.interval = 30;
        this.timer.intervalSubscription.next();
        interval(1000).pipe(takeUntil(this.timer.intervalSubscription)).subscribe(x => {
          this.timer.interval--;
        });
      }
      if(response.length>0){
        console.log(response,this.data);
        this.inputData.orders = response;
        if(this.userService.getRole()=='CONT_SUPERVISOR' && response[0].companyCode!=this.data.contractingCompanyCode.split("-@-")[1])
        {        
          this.criteria = {r:false,u:false,h:false,s:false};
        }
        else{
          this.criteria = this.searchService.getActions(this.userService.getRole(),moment(response[0].assignmentStart).format('YYYY-MM-DD'),response[0].priority,response[0].status,moment(response[0].earlyStartDate).format('YYYY-MM-DD'),response[0].district);
        }
        
      }
      else{
        this.inputData.orders = []
        this.criteria = {r:false,u:false,h:false,s:false};
      }
      
      this.loader.hideLoader();
    }, (error) => {
      this.loader.hideLoader();
      this.toastService.error("There was an error please try again later");
    });
  }
  openSchedule(data){
    console.log(this.data.contractingCompanyCode);
    const dialog = this.matDialog.open(AppointmentModalComponent, {
      maxWidth: '80vw',
      disableClose: true,
      data: {
        title:"Schedule",
        companyid:this.data.contractingCompanyCode,
        selectedOrders:cloneDeep(data),
       },
      panelClass:"appointment-container"
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        
        this.loadComponentsData();
       
        
        
      }
    });
  }
  ngOnDestroy() {
    this.pendingStopPolling.next(true);
    this.pendingStopPolling.complete();
    this.timer.intervalSubscription.next(true);
    this.timer.intervalSubscription.complete();
    this.ordersService.searchValue = null;
  }
}
