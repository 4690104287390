import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { districtTimeZone, order } from './../../app.config';
import { LoaderService } from 'src/app/services/loader.service';
import { OrdersService, Recommend } from 'src/app/services/orders.service';
import { ToastService } from 'src/app/services/toast.service';
import * as moment from 'moment'; import 'moment-timezone';
import forIn from "lodash-es/forIn";
import { AlertsComponent } from '../alerts/alerts.component';
import { uniq } from 'lodash-es';
@Component({
  selector: 'app-recommend-modal',
  templateUrl: './recommend-modal.component.html',
  styleUrls: ['./recommend-modal.component.scss']
})
export class RecommendModalComponent implements OnInit {
  moment = moment;
  fsrRecommended: any;
  mapping = {
    "primaryFsr": "Employees",
    "supportedFsr": "Supported",
    "totalContractor": "Contractors"
  }
  dateConfig = {
    startTime: null,
    getCurrentTimeZone:null,
    selectedDate: null,
    minDate: null,
    maxDate:null,
  }
  priorityValidate = { '1': { current: true, future: false, nextDay: true }, '2': { current: true, future: false, nextDay: false }, '3': { current: true, future: true, nextDay: false }, '4': { current: true, future: true, nextDay: true }, '5': { current: true, future: true, nextDay: true }, '6': { current: true, future: true, nextDay: true }, '7': { current: true, future: true, nextDay: true }, '8': { current: true, future: true, nextDay: true } };
  
  constructor(@Inject(MAT_DIALOG_DATA) public inputData, public dialogRef: MatDialogRef<RecommendModalComponent>, private ordersService: OrdersService, private toastService: ToastService, private matDialog: MatDialog, private loader: LoaderService) { }

  ngOnInit(): void {
    this.getTimeZone();
  }

  getTimeZone() {
    const timeZone = districtTimeZone[this.inputData.orders[0].district];
    let stateTime = districtTimeZone ? moment.tz(new Date(), timeZone).format("HH:mm") : moment();
    let startDate = districtTimeZone ? moment.tz(new Date(), timeZone).format("YYYY-MM-DD") : moment();
    this.dateConfig.minDate = this.dateConfig.selectedDate = moment(startDate,"YYYY-MM-DD");
    this.dateConfig.maxDate =  moment(startDate,"YYYY-MM-DD").add(30, "days");
    this.dateConfig.startTime = stateTime;
    this.dateConfig.getCurrentTimeZone = startDate
  }


  recommendOrder() {
    // this.showError = this.fsrRecommended ? false : true;
    let accessToken = JSON.parse(window.localStorage['okta-token-storage']);
    let date = { orderStartTime: moment(this.dateConfig.startTime, ["HH:mm"]).format("HH:mm"), orderStartDate: this.dateConfig.selectedDate.format("YYYY-MM-DD") }
    let error = { nextDayValid: { order: [] }, dueDateError: [], priorityError: { order: [], priority: [] }, contractors: { order: [], company: [], fsrName: [] } }
    let dateSet = { dataSetObject: [], invalidOrders: [], validOrders: [], companyCode: null };
    let dateStatus;
    let assignmentTime = moment(date.orderStartDate + ' ' + date.orderStartTime);
    
    if (this.fsrRecommended) {
      //this.loader.showLoader()
      let nextDay = moment(this.dateConfig.getCurrentTimeZone).add(1, 'days');
      this.inputData.orders.map(item => {
        if (item.priority != 1) { //checks the date Status
          dateStatus = this.dateConfig.selectedDate.isSame(this.dateConfig.getCurrentTimeZone) ? 'current' : this.dateConfig.selectedDate.isAfter(this.dateConfig.getCurrentTimeZone) ? 'future' : null;
        } else {
          dateStatus = this.dateConfig.selectedDate.isSame(this.dateConfig.getCurrentTimeZone) ? 'current' : (this.dateConfig.selectedDate.isAfter(this.dateConfig.getCurrentTimeZone) && !nextDay.isSame(date.orderStartDate)) ? 'future' : (this.dateConfig.selectedDate.isAfter(this.dateConfig.getCurrentTimeZone) && nextDay.isSame(date.orderStartDate)) ? 'nextDay' : null;
        }
        //Due Date Validation
        if ((moment(date.orderStartDate).isSameOrBefore(item.dueDate)) || item.priority === '2' || item.priority === '1') {
         //Priority Validation
          if (this.priorityValidate[item.priority][dateStatus]?(item.priority==3?moment(assignmentTime).isSame(moment(item.appointmentStart),'day'):true):false) {     
            // recommend to Same Company contractors and accept AMWATER orders to contractors
            if (item.companyName !== this.fsrRecommended.companyName && item.companyCode !== 'AMWATER') {
              error.contractors.order.push(item.workOrderNumber);
              dateSet.invalidOrders.push(item);
            } else {
              item.assignmentStart = assignmentTime;
              item.assignmentFinish = moment(item.assignmentStart).add(item['taskDuration'], 'm')
              assignmentTime = item.assignmentFinish;
              let object: Recommend = {
                "assignedEngineer": this.fsrRecommended.mappingPersonnelNumber ? this.fsrRecommended.mappingPersonnelNumber : this.fsrRecommended.personnelNumber,
                "assignmentFinish": moment(item.assignmentFinish).format('YYYY-MM-DD HH:mm:ss'),
                "assignmentStart": moment(item.assignmentStart).format('YYYY-MM-DD HH:mm:ss'),
                "dispatcherId": accessToken ? accessToken.idToken?.claims.preferred_username.split('@')[0] : "",
                "engineerId": this.fsrRecommended.userId,
                "itemTimeStamp": moment().format("YYYY-MM-DD HH:mm:ss"),
                "operationNo": "0010",
                "status": item.status,
                "workOrderNo": item.workOrderNumber
              };
              //Check if Day is shifted to next day while recommending
              if(this.fsrRecommended.companyName === 'AMWATER')
              {
              if(moment(object.assignmentFinish,"YYYY-MM-DD").isSameOrBefore(this.dateConfig.selectedDate)){
               dateSet.dataSetObject.push(object);
              } else {
                error.nextDayValid.order.push(item.workOrderNumber);
                dateSet.invalidOrders.push(item);
              }
            }
            else{
              dateSet.dataSetObject.push(object);
            }
          }
            // Contractor Validation should not be allowed to AMWATER FSR
            if (item.companyName !== 'AMWATER' && this.fsrRecommended.companyName === 'AMWATER') {
              error.contractors.fsrName.push(item.workOrderNumber);
              dateSet.invalidOrders.push(item);
            }

          } else {  //Priority Validation else
            dateSet.invalidOrders.push(item);
            error.priorityError.order.push(item.workOrderNumber)
            error.priorityError.priority.push(item.priority)
          }

        } else {  //Due Date Validation else
          dateSet.invalidOrders.push(item);
          error.dueDateError.push(item.workOrderNumber)
        }
      })
      //API call if all are valid orders
      if (dateSet.invalidOrders.length == 0) {
        this.postRecommendOrders(dateSet.dataSetObject);
      } else {
        let priorityMessage = error.priorityError.order.length > 0 ? `Work Orders:('${error.priorityError.order}') with Priority '${uniq(error.priorityError.priority)}' can't be Recommended to this Date` : '';
        let dueDateMessage = error.dueDateError.length > 0 ? `Work Orders:('${error.dueDateError}') Due Date is early then Selected Date ` : '';
        let contractors = error.contractors.fsrName.length > 0 ? `Contractor Work Orders:('${error.contractors.fsrName}') cant be recommended to Amwater FSR` : error.contractors.order.length > 0 ? `Work Orders:('${error.contractors.order}') does not belongs to ${this.fsrRecommended.companyName}` : null;
        let nextDayMsg = error.nextDayValid.order.length > 0 ?  `Work Orders:('${error.nextDayValid.order}') have assignment Finish that can't be pushed to the next Day` : null; 
        let message = contractors ? contractors : nextDayMsg ? nextDayMsg : `${dueDateMessage} , ${priorityMessage}`
        let alertObject = {
          type: "alert",
          title: "Recommend Error",
          message: `${message}`,
        };
        this.matDialog.open(AlertsComponent, {
          data: alertObject,
          hasBackdrop: true,
          disableClose: true,
          minWidth: "300px",
          maxWidth: "32vw",
        });
      }
    } else {
      this.loader.hideLoader();
      this.toastService.error("Select one Employee to recommend");
    }
  }
  postRecommendOrders(orders) {
    this.loader.showLoader()
    this.ordersService.recommendWorkOrders(orders, moment(this.dateConfig.selectedDate).isSameOrBefore(this.dateConfig.minDate)).subscribe((response) => {
      // console.log(Object.keys(response));
      let success = []
      let failed = []
      forIn(response, (value, key) => {
        // console.log(key, value);
        if (value) {
          success.push(key);
        } else {
          failed.push(key);
        }
      });
      if (success.length > 0) {
        this.toastService.success(`Work Orders.('${success}') recommeded successfully`);
      }
      if (failed.length > 0) {
        this.toastService.error(`Work Orders.('${failed}') failed to recommend`);
      }
      this.dialogRef.close(true);
      //this.toastService.success("Recommeded workorder " + success.join(",") + " successfully ");
      //this.toastService.error("Recommed workorder " + failed.join(",") + " failed");


      this.loader.hideLoader();
    }, (error) => {
      console.log(error);
      this.loader.hideLoader();
      this.toastService.error("There was an error please try again later");
    })
  }
  applyFilter(event: Event, data, valueCheck) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    data = data.map(item => {
      item.hide = item[valueCheck] && item[valueCheck].toLowerCase().includes(filterValue) ? false : true
      return item
    });
  }
  onTimeChange(event) {
    this.dateConfig.startTime =event;
  }

}
