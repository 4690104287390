import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { OrdersGridComponent } from '../orders-grid/orders-grid.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/services/toast.service';
import { LoaderService } from 'src/app/services/loader.service';
import { OrdersService ,SoScheduleUpdate} from 'src/app/services/orders.service';
import { SharedService } from 'src/app/services/shared.service';
import * as moment from 'moment';
import groupBy from "lodash-es/groupBy";
import { forkJoin } from 'rxjs';
import { AlertsComponent } from '../alerts/alerts.component';


@Component({
  selector: 'app-appointment-modal',
  templateUrl: './appointment-modal.component.html',
  styleUrls: ['./appointment-modal.component.scss']
})
export class AppointmentModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public inputData: any,public dialogRef: MatDialogRef<AppointmentModalComponent>,private toastService: ToastService, private loader: LoaderService, private ordersService: OrdersService, private sharedService:SharedService,private matDialog: MatDialog) { }
  @ViewChild(OrdersGridComponent) ordersGrid: OrdersGridComponent;
  datepicker ={
    data:[],
    dates:{
      start:moment(),
      end:moment()
    },
    buttonDisable:{
      left:false,
      right:false
    },
    noOfDays:10
  }
  isCurrentDate = true;
  allSlots:any =[];
  moment=moment;
  
  selectedData = {
    slot:null,
    date:null,
    tab:1
  }
  ngOnInit(): void {
   this.getTimeSlots();
   this.onPickerChanges('initlize');
  }

onPickerChanges(type){
  switch(type){
    case 'increment':
      this.datepicker.dates.start =this.datepicker.dates.end.add(1,'day');
      this.datepicker.dates.end = this.datepicker.dates.start.clone().add(this.datepicker.noOfDays-1,'days');
      this.getDatePickerData(this.datepicker.dates);
      this.datepicker.buttonDisable.left = false;
      break
    case 'decrement':
      this.datepicker.dates.end = this.datepicker.dates.start.subtract(1,'day');
      this.datepicker.dates.start = this.datepicker.dates.end.clone().subtract(this.datepicker.noOfDays-1,'days');
      this.getDatePickerData(this.datepicker.dates);
      this.datepicker.buttonDisable.left = this.datepicker.dates.start.clone().isSame(moment(),'day');
      break
  case 'initlize':
      
      this.datepicker.dates.end = this.datepicker.dates.start.clone().add(this.datepicker.noOfDays-1,'days');
      this.getDatePickerData(this.datepicker.dates);
      this.datepicker.buttonDisable.left = true;
      break
  }
}
getDatePickerData(dates){
  this.loader.showLoader();
  console.log(dates)
  this.ordersService.getContartorAppointmentOrdersByDateRange(this.inputData.companyid,dates.start.format("YYYY-MM-DD"),dates.end.format("YYYY-MM-DD")).subscribe((response:any)=>{
    this.loader.hideLoader();
    console.log(response);
  let groupedData = null
    if(response && response.data.length>0){
      groupedData = groupBy(response.data,(v) => {
           return moment(v.appointmentStart).format('YYYY-MM-DD')
         });
    }
    else{
      console.log(response.errors[0].message);
    }
   this.datepicker.data = this.getDatePickerArray(dates.start,dates.end,groupedData);
   //this.selectedData.date = this.datepicker.data[0]
   this.onDateChange(this.datepicker.data[0]);
   console.log(this.selectedData.date);
    
  },(error)=>{
    this.loader.hideLoader();
    this.toastService.error("There was an error please try again later");
    console.log("Error from server", error);
  });
}
 
getDatePickerArray(start,end,orders){
  let arr=[];
  let startDate = start.clone();
  let endDate = end.clone();
  console.log(orders);
  while(startDate.isSameOrBefore(endDate))
  {
    let ordersData = orders?orders[startDate.format("YYYY-MM-DD")]:null;
    let obj = {
      date:startDate.format("MMM DD"),
      day:startDate.format('dddd'),
      orders:ordersData?ordersData:[],
      dateFull:startDate.format("YYYY-MM-DD")
    }
    arr.push(obj);
    startDate.add(1,"day");
  }
  return arr
}
onDateChange(data){
  this.selectedData.date =data;
  console.log(data);
  this.isCurrentDate = moment(data.dateFull).isSame(moment(),'day');
  if(this.isCurrentDate){
    this.selectedData.slot =this.allSlots[0];
  }
}
getTimeSlots(){
  
  this.loader.showLoader();
  this.ordersService.getSlotsForAppointments(this.inputData.companyid).subscribe(
    (response:any)=>{
      this.loader.hideLoader();
      console.log(response);
      if(response && response.data.length>0){
        this.allSlots = response.data;
        this.selectedData.slot = response.data[0];
      }
      else{
        this.toastService.error(response.errors[0].message);
      }
  },
  (error)=>{
    this.loader.hideLoader();
    this.toastService.error("There was an error please try again later");
    console.log("Error from server", error);
  })
}

onSchedule(){
let dataObjArr=[];
let userData =  JSON.parse(window.localStorage['okta-token-storage'])
this.loader.showLoader();
console.log(this.inputData.selectedOrders);
  for(let i=0;i<this.inputData.selectedOrders.length;i++){
    let dataObj:SoScheduleUpdate = {
      "workorder":'000'+this.inputData.selectedOrders[i].workOrderNumber,
      "priority":"3",
      "dueDate":moment(this.selectedData.date.dateFull).format("YYYYMMDD"),
      "appointmentStart" :moment(this.selectedData.date.dateFull+" "+this.selectedData.slot.startTime).format("YYYYMMDDHHmmss"),
      "appointmentEnd" :moment(this.selectedData.date.dateFull+" "+this.selectedData.slot.endTime).format("YYYYMMDDHHmmss"),
      "status" :'UNSC',
      "earlyStartDate" :moment(this.selectedData.date.dateFull).format("YYYYMMDD"),
      "userName" :userData.idToken?.claims.preferred_username.split('@')[0] ,
      "companyName":this.inputData.companyid.split("-@-")[0],
      "longText" :`${this.inputData.selectedOrders[i].priority==3?'Contractor Rescheduled':'Contractor Appointment'} Service Order # ${this.inputData.selectedOrders[i].workOrderNumber} of type ${this.inputData.selectedOrders[i].matCode} - ${this.inputData.selectedOrders[i].matCodeDescription} rescheduled to a customer appointment. Appointment set for ${moment(this.selectedData.date.dateFull).format("MM/DD/YYYY")} ${moment(this.selectedData.slot.startTime,"HH:mm").format("hh:mm A")} - ${moment(this.selectedData.slot.endTime,"HH:mm").format("hh:mm A")} with contractor ${this.inputData.companyid.split("-@-")[0]} by ${userData.idToken.claims.name}.`,
    }
   
    dataObjArr.push(this.ordersService.soStatusUpdateWorkOrder(dataObj));
  }
  console.log(dataObjArr);
  forkJoin(dataObjArr).subscribe((response:any)=>{
    
    console.log(response);
    let successArr = [];
    let errorArr= [];
    for(let i=0;i<response.length;i++){
      if(response[i].success=="S"){
        successArr.push(response[i].message.split(":")[1].substring(3));
      }
      else{
        errorArr.push(response[i].message.split(":")[1].substring(3));
      }
    }
    let message = '';
    if(successArr.length>0){
      message = message+ `<p>Order(s) ${successArr.join()} Scheduled</p>`
    }
    if(errorArr.length>0){
      message = message+`<p class="error_message">Order(s) ${errorArr.join()} were unable to Schedule</p>`
    }
    
    const alertObject = {
      type: 'alert',
      title: 'Order Updates Complete',
      message: message,
    };
    this.matDialog.open(AlertsComponent, { data: alertObject, hasBackdrop: true, disableClose: true, width: "52vw" }).afterClosed().subscribe((res)=>{;
    
    this.dialogRef.close(response);
    this.loader.hideLoader();
  })
  },(error)=>{
    this.loader.hideLoader()
    console.log(error);
  })


}

}