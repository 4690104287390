import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/header/header.component';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { environment } from '../environments/environment';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { LoaderComponent } from './components/loader/loader.component';
import { MatBadgeModule } from '@angular/material/badge';
import {DataService} from '../app/services/notifyChange.service';
import { LegendComponent } from './components/legend/legend.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BannerComponent } from './components/banner/banner.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SearchModule } from './module/search/search.module';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import {
  OKTA_CONFIG,
  OktaAuthModule,
} from '@okta/okta-angular';
const oktaAuth = new OktaAuth(environment.okta as OktaAuthOptions);
@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        LoaderComponent,
        LegendComponent,
        BannerComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatIconModule,
        HttpClientModule,
        MatDialogModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        OktaAuthModule,
        MatMenuModule,
        MatBadgeModule,
        DragDropModule,
        MatButtonModule,
        MatFormFieldModule,
        FormsModule, ReactiveFormsModule,
        MatInputModule,
        MatProgressBarModule,
        SearchModule
    ],
    providers: [
        {
            provide: OKTA_CONFIG,
            useValue: { oktaAuth }
        },
        { provide: LocationStrategy, useClass: PathLocationStrategy }, DataService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
