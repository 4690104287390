<!-- <div class="dark-overlay">
    <div class="spinner_container">
        <mat-spinner color="warn" diameter="100">
        </mat-spinner>
        <h1 class="loading-text">Loading...</h1>
    </div>
</div> -->
<ng-template #progressSpinnerRef>
    <div>
    <mat-spinner color="warn" diameter="100">
    </mat-spinner>
    <h1 class="loading-text">Loading...</h1>
</div>
</ng-template>
