import { API_URL,pendingOrder_Columns,RestrictPendingColumns } from '../app.config';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpService:HttpService) { }


  getUserPreferences(employeeId){
    const url = API_URL.getUserPreferences.replace("{personnelNumber}",employeeId);
    return this.httpService.apiRequest(url,'GET');
  }
  saveUserPreferences(employeeId,data){
    let url = API_URL.saveUserPreference;
    let updatedData={
      personnelNumber:employeeId,
      userPreference:data
    }
    return this.httpService.apiRequest(url, 'POST',updatedData);
  }
  getEmployeeId() {
    const employee = JSON.parse(localStorage.getItem("okta-token-storage"));
    let employeeid;
    if (employee != null && employee["idToken"]["claims"] != null) {
      employeeid = employee["idToken"]["claims"]["Employee_ID"];
    }
    else {
      console.log("employee id not found of current user to fetch user preferences");
    }
    return employeeid;
  }

  loadDefaultData() {
    // debugger;
    let arr = pendingOrder_Columns.split(",");
    let columns = arr.map(x => { return { name: x.split("-")[0], selected: JSON.parse(x.split("-")[1]), showAlways: false } });
  
    return columns;
  }
  loadRestricted(columns) {
    let cols = RestrictPendingColumns.split(",");
    if (cols != null && cols.length > 0) {
      cols.forEach(element => {
        let temp = columns.filter(x => { return x.name == element });
        if (temp != null && temp.length > 0) {
          temp[0]["selected"] = true;
          temp[0]["showAlways"] = true;
        }
      });
    }
    return columns;
  }
  getOktaTokenStorage(){
    return window.localStorage['okta-token-storage']?JSON.parse(window.localStorage['okta-token-storage']):false;
  }

  getRole(){
    let accessToken = this.getOktaTokenStorage();
    if(accessToken){
      if(accessToken.accessToken.claims.W1V.includes(environment.userRoles.ADMIN)){
        return "ADMIN";
      } 
      if(accessToken.accessToken.claims.W1V.includes(environment.userRoles.FRCC)){
        return "FRCC";
      } 
      if(accessToken.accessToken.claims.W1V.includes(environment.userRoles.AW_SUPERVISOR)){
        return "AW_SUPERVISOR";
      }
      if(accessToken.accessToken.claims.W1V.includes(environment.userRoles.CONT_SUPERVISOR)){
        return "CONT_SUPERVISOR";
      }
    }
    else{
      console.log("no role",window.localStorage['okta-token-storage']);
      return "NO_ACCESS_TOKEN";
    }
  }

  hasSupportRole(){
    return this.hasEXTSupportRole() || this.hasINTSupportRole();
  }

  hasINTSupportRole(){
    let accessToken = this.getOktaTokenStorage();
    if(accessToken){
      if(accessToken.accessToken.claims.W1V.includes("W1V_SUPPORT_INT"))
        return true;
    }
    return false;
  }
  
  hasEXTSupportRole(){
    let accessToken = this.getOktaTokenStorage();
    if(accessToken){
      if(accessToken.accessToken.claims.W1V.includes("W1V_SUPPORT_EXT"))
        return true;
    }
    return false;
  }

}