<!-- <div class="miu-main-container"> -->
  <!-- <div class="back-btn" (click)="navigateback()"><img src="assets/images/header/back-icon.png" /></div> -->
  <!-- <div class="container"> -->
    <div class="header-wrapper-validator" mat-dialog-title>
      <div class="validator-header"><img src="/assets/icons/search.svg" /> Inventory Checker</div>
      <button mat-icon-button class="close-button headerclose" mat-dialog-close>
        <mat-icon class="close-icon " color="primary">close</mat-icon>
      </button>
    </div>
    <mat-tab-group>
      <mat-tab label="Meter">
        <div class="miufield">
          <div class="meter-field-all" style="display: flex;">
            <mat-drawer #drawer opened="true" class="left-side-content" mode="side"
              style="max-height: 432px; overflow-y: scroll;width: '248' !important;">
              <app-state-district-selector [mode]="'single'" outputFormat="district"
                [inputFormat]="{type:'string',key:''}" [dataSource]="allStatesAndDistricts"
                (onSelection)="stateDistrictSelectionChange($event)"></app-state-district-selector>
            </mat-drawer>

            <div class="meter-input-fields">
              <mat-form-field class="validator-form-field" [ngClass]="{'manufacturer-selected': manufacturerSelected }">
                <mat-label [ngClass]="{'manufacturer-selected': manufacturerSelected }"
                  *ngIf="manufacturerSelected === ''" class="meter-select-label">Select Meter Manufacturer</mat-label>
                <mat-select [(ngModel)]="manufacturerSelected" [disableOptionCentering]="true" panelClass="myPanelClass"
                  class="mat-select-validator">
                  <mat-option *ngFor="let meterManufacturer of meterManufactures" [value]="meterManufacturer.value"
                    class="option-container">
                    {{meterManufacturer.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="fieldtitle"><span class="meterNumberText"> If a Meter number is </span><span
                  class="notValidatedText">not validated </span> <img src="/assets/icons/validationstatus/error.svg" /><span
                  class="notvalid">, do not use.</span></div>
              <div class="input-grid">
                <div *ngFor="let index of[0, 1, 2, 3, 4, 5]; let i = index" class="input-row">
                  <div class="input-column" *ngFor="let col of [0, 1, 2]; let j = index">
                    <input #userMeterInput class="meterinputfield" type="text" id="meterInput{{i * 3 + j}}" placeholder="Meter Number"  (keypress)="alphaNumericMeter($event,(i * 3 + j))"
                      [(ngModel)]="meterNumbers['field' + (i * 3 + j)]" (focus)="isFocused[i * 3 + j] = true"
                      (blur)="isFocused[i * 3 + j] = false"
                      [ngClass]="{'pass': meterNumbers['field' + (i * 3 + j)] &&  validMeterSerial.indexOf(meterNumbers['field' + (i * 3 + j)]) >= 0, 'fail': meterNumbers['field' + (i * 3 + j)] &&  invalidMeterSerial.indexOf(meterNumbers['field' + (i * 3 + j)]) >= 0}" />
                    <label for="meterInput{{i * 3 + j}}" class="meter-label"
                      [ngClass]="{'labelPass': meterNumbers['field' + (i * 3 + j)] &&  validMeterSerial.indexOf(meterNumbers['field' + (i * 3 + j)]) >= 0, 'labelFail': meterNumbers['field' + (i * 3 + j)] &&  invalidMeterSerial.indexOf(meterNumbers['field' + (i * 3 + j)]) >= 0}"><b>
                        {{meterMap.get(i * 3 + j)[0]}}</b> {{meterMap.get(i * 3 + j)[1]}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btnfield">
            <button class="clear" (click)="clearAllMeter()">Clear All</button>
            <button class="validate-meter" [disabled]="!manufacturerSelected || selectedDistrict == null || isMeterArrayPopulated()"
              (click)="validateMeters(meterNumbers)">Validate</button>
          </div>
          <div class="btn-buffer"></div>
        </div>
      </mat-tab>

      <mat-tab label="MIU">
        <div class="miufield">
          <div class="meter-field-all" style="display: flex;">
            <mat-drawer #drawer opened="true" class="left-side-content" mode="side"
              style="max-height: 432px; overflow-y: scroll;width: '248' !important;">
              <app-state-district-selector [mode]="'single'" outputFormat="district"
                [inputFormat]="{type:'string',key:''}" [dataSource]="allStatesAndDistrictsMIU"
                (onSelection)="stateDistrictSelectionChangeMIU($event)"></app-state-district-selector>
            </mat-drawer>

            <div class="meter-input-fields">
              <mat-form-field class="validator-form-field" [ngClass]="{'manufacturer-selected': manufacturerSelectedMIU }">
                <mat-label [ngClass]="{'manufacturer-selected': manufacturerSelectedMIU }"
                  *ngIf="manufacturerSelected === ''" class="meter-select-label">Select MIU Manufacturer</mat-label>
                <mat-select [(ngModel)]="manufacturerSelectedMIU" [disableOptionCentering]="true" panelClass="myPanelClass"
                  class="mat-select-validator">
                  <mat-option *ngFor="let miuManufacturer of miuManufactures" [value]="miuManufacturer.value"
                    class="option-container">
                    {{miuManufacturer.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="fieldtitle"><span class="meterNumberText"> If a MIU number is </span><span
                  class="notValidatedText">not validated </span> <img src="/assets/icons/validationstatus/error.svg" /><span
                  class="notvalid">, do not use.</span></div>
              <div class="input-grid">
                <div *ngFor="let index of[0, 1, 2, 3, 4, 5]; let i = index" class="input-row">
                  <div class="input-column" *ngFor="let col of [0, 1, 2]; let j = index">
                    <input #userMeterInput class="meterinputfield" type="text" id="miuInput{{i * 3 + j}}" placeholder="MIU Number"  (keypress)="alphaNumericMeter($event,(i * 3 + j))"
                      [(ngModel)]="miuNumbers['field' + (i * 3 + j)]" (focus)="isFocused[i * 3 + j] = true"
                      (blur)="isFocused[i * 3 + j] = false"
                      [ngClass]="{'pass': miuNumbers['field' + (i * 3 + j)] &&  validMiuSerial.indexOf(miuNumbers['field' + (i * 3 + j)]) >= 0, 'fail': miuNumbers['field' + (i * 3 + j)] &&  invalidMiuSerial.indexOf(miuNumbers['field' + (i * 3 + j)]) >= 0}" />
                    <label for="miuInput{{i * 3 + j}}" class="meter-label"
                      [ngClass]="{'labelPass': miuNumbers['field' + (i * 3 + j)] &&  validMiuSerial.indexOf(miuNumbers['field' + (i * 3 + j)]) >= 0, 'labelFail': miuNumbers['field' + (i * 3 + j)] &&  invalidMiuSerial.indexOf(miuNumbers['field' + (i * 3 + j)]) >= 0}"><b>
                        {{miuMap.get(i * 3 + j)[0]}}</b> {{miuMap.get(i * 3 + j)[1]}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btnfield">
            <button class="clear" (click)="clearAllMiu()">Clear All</button>
            <button class="validate-meter" [disabled]="!manufacturerSelectedMIU || selectedDistrictMIU == null || isMIUArrayPopulated()"
              (click)="validateMius(miuNumbers)">Validate</button>
          </div>
          <div class="btn-buffer"></div>
        </div>
      </mat-tab>
    </mat-tab-group>

  <!-- </div>
</div> -->