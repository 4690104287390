<div mat-dialog-title>
    <div class="headertitle">{{data.title}}</div>
    <button mat-icon-button class="close-button headerclose" mat-dialog-close>
      <mat-icon class="close-icon " color="primary">close</mat-icon>
    </button>
  </div>
  <!--Main dialog Content-->
  <!-- <div > -->
    <form [formGroup]="holdStatus" class="single-select-list" >
    <mat-dialog-content class="dialog-container">
        <mat-selection-list class="hold-selection-list"#lists [multiple]="false" formControlName="reason">
            <mat-list-option [value]="list" *ngFor="let list of data.list" >
            <span (click)="changeReason(list)">{{list}}</span>  
            </mat-list-option>
          </mat-selection-list>
      <mat-form-field  *ngIf="holdStatus.value.reason=='Other'"   appearance="outline" class='dark-input '  >
        <mat-label>Other Reason</mat-label>
        <textarea formControlName="otherReason" matInput [required]="holdStatus.value.reason=='Other'" >
        </textarea>
        <mat-error>Please enter a Reason</mat-error>
      </mat-form-field>  
    </mat-dialog-content>
    <button [disabled]="!holdStatus.value.reason || (holdStatus.value.reason=='Other' && !holdStatus.value.otherReason)" class="accent-darker" (click)="onDiaglogAction()" mat-flat-button>{{data.button}}</button>

  </form>
 
  <!--Action buttons-->

  <!-- <div mat-dialog-actions class="action-buttons" align="end">
    <button *ngIf='data.type=="confirm"' class="cancel-button" mat-button mat-flat-button mat-dialog-close >{{data.buttonNegtiveText?data.buttonNegtiveText:'Cancel'}}</button>
    <button mat-button mat-flat-button color='warn' (click)="onDiaglogAction()" cdkFocusInitial>{{data.buttonPositiveText?data.buttonPositiveText:'OK'}}</button>
  </div> -->
  