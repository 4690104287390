import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { BannerService } from 'src/app/services/banner.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  notifications:any[] = [];
  private _subscription: Subscription;
  textCounter: any = 365;
  firstCount = 100;
  notificationCollection: any = []

  constructor(
    private bannerService: BannerService
  ) { }

  

  ngOnInit() {
    this._subscription = this.bannerService.getObservable().subscribe((notification: any) => {
      if (notification.length > 0) {
        let ids: any = [];
        let modifiedDataReceive: any = [];
        localStorage.notificationList != undefined ? ids = JSON.parse(localStorage.notificationList) : '';
        if (ids.length > 0) {
          ids.map(loc_item => {
            notification.map(API_item => {
              // This conidition checks if response 'id' matches with localStorage 'id' and enter into it 
              if (loc_item.id == API_item.id) {
                // This conidition checks if response 'updatedOn' matches with localStorage 'updatedOn' then pushes to the banner notification list  
                if (loc_item.updatedOn != API_item.updatedOn) {
                  let existdata: any = (localStorage.notificationList != undefined ? JSON.parse(localStorage.notificationList) : '');
                  let afterRemoveDataFromLocalStorage = existdata.filter((elm) => elm.id != API_item.id);
                  localStorage.setItem('notificationList', JSON.stringify(afterRemoveDataFromLocalStorage));
                }
              }
            });
          });
        }
        let dataReceived = notification.filter(item => ids.every(item1 => item1.id != item.id));
        this.notifications = [...dataReceived, ...modifiedDataReceive];
        this.notifications = this.notifications.sort(this.sortData);
      }
    });
  }
sortData(a,b){
    return a.id-b.id;
}
  close(banner, index: any) {
    let existdata = (localStorage.notificationList != undefined ? JSON.parse(localStorage.notificationList) : '');
    let newRecord = [{ id: banner.id, updatedOn: banner.updatedOn }];
    this.notificationCollection = [...existdata, ...newRecord];
    localStorage.setItem('notificationList', JSON.stringify(this.notificationCollection));
    this.notifications = this.notifications.filter(notif => notif.id !== banner.id);
    
  }
  
  getClassName(banner): string {
    let style: string;
    switch (banner.type) {

      case 'RELEASE':
        style = 'success make-animate';
        break;

      case 'INFORMATION':
        style = 'info make-animate';
        break;

      case 'ALERT':
        style = 'error make-animate';
        break;

      default:
        style = 'warning make-animate';
        break;
    }
    return style;
  }
  getIconName(banner): string {
    let icon: string;
    switch (banner.type) {

      case 'RELEASE':
        icon = 'release';
        break;

      case 'INFORMATION':
        icon = 'information';
        break;

      case 'ALERT':
        icon = 'alert';
        break;

      default:
        icon = 'alert_tick';
        break;
    }
    return icon;
  }
  toggleShowText(index) {
    this.notifications[index].showMore = !this.notifications[index].showMore;
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
