<div class="mat-dialog-title header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <h1>Legend</h1>
    <button mat-icon-button class="close-button" mat-dialog-close>
        <mat-icon >close</mat-icon>
    </button>
</div>
<div mat-dialog-content >
    <div class="container">
    <div class="section ">
        <h4 class="title">Status</h4>
        <ul>
            <li *ngFor="let stat of status" >
                <span [ngClass]="stat.class">{{stat.label}}</span>
            </li>
        </ul>
    </div>
    <div>
    <div class="section ">
        <h4 class="title">Priority</h4>
        <ul>
            <li *ngFor="let priority of priority" >
                <span [ngClass]="priority.class">{{priority.label}}</span>
            </li>
        </ul>
    </div>
    <div class="section ">
        <h4 class="title">Map Pins</h4>
        <ul class="image_description">
            <li *ngFor="let pin of mapPins" >
                <img [src]="pin.image">
                <p>{{pin.label}}</p>
            </li>
        </ul>
    </div>
    </div>
    <div>
        <div class="section ">
            <h4 class="title">Order Type</h4>
            <ul class="image_description">
                <li *ngFor="let order of orderTypes" >
                    <img *ngIf="order.image" [src]="order.image">
                    <p>{{order.label}}</p>
                </li>
            </ul>
        </div>
        <div class="section ">
            <h4 class="title">FSR Status</h4>
            <ul class="icon_description">
                <li *ngFor="let status of fsrStatus" >
                    <i class="material-icons" [ngClass]="status.class"  >{{status.icon}}</i>
                    <p>{{status.label}}</p>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="assigned_pins_section">
    <h4 class="title">FSR Assigned Work Orders</h4>
    <div class="assigned_pins_container">
        <ng-container *ngFor="let pin of status" >
    <div class="assigned_pins_content"  *ngIf="pin.label!=='Blocked'" >
        <img  [src]='"../assets/icons/status/fsrorder-"+pin.class+".svg"'>
        <p>{{pin.label}}</p>
    </div>
</ng-container>
    </div>
</div>
</div>


