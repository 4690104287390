import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Overlay,OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @ViewChild('progressSpinnerRef')
  private progressSpinnerRef: TemplateRef<any>;
  private spinnerTopRef ;
  
  @Input() displayProgressSpinner: boolean;
  constructor(private overlay: Overlay,private vcRef: ViewContainerRef) { }

  ngOnInit(): void {
    this.spinnerTopRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position()
          .global()
          .centerHorizontally()
          .centerVertically()
  });
    
  }
  ngDoCheck() {
    // Based on status of displayProgressSpinner attach/detach overlay to progress spinner template
    if (this.displayProgressSpinner && !this.spinnerTopRef.hasAttached()) {
      this.spinnerTopRef.attach(new TemplatePortal(this.progressSpinnerRef,this.vcRef))
    } else if (!this.displayProgressSpinner && this.spinnerTopRef.hasAttached()) {
      this.spinnerTopRef.detach();
    }
  }

}
