import { API_URL, order } from './../app.config';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import * as moment from 'moment';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import sortBy from "lodash-es/sortBy";
export interface Recommend {
  "assignedEngineer":string,
"assignmentFinish": string,
"assignmentStart": string,
"dispatcherId": string,
"engineerId":string,
"itemTimeStamp": string,
"operationNo": string,
"status":string,
"workOrderNo": string
};
export interface SoStatusUpdate{
    "statusNonNumber":string,
    "statusNumber":string,
    "statusNotes": string,
    "assignedEngineer": string,
    "assignmentEnd":string,
    "assignmentStart":string,
    "dispatchId": string,
    "engineerId":string,
    "itemTimeStamp":string,
    "operationNumber":string,
    "workOrderNumber":string,
}
export interface SoScheduleUpdate{
  "workorder":string,
  "priority":string,
  "dueDate":string,
  "appointmentStart" :string,
  "appointmentEnd" :string,
  "status" :string,
  "earlyStartDate" :string,
  "userName" :string,
  "companyName":string,
  "longText" :string,
  }
export interface Unschedule{
    "assignedEngineer":string,
    "assignmentEnd": string,
    "assignmentStart":string,
    "dispatchId":string,
    "engineerId":string ,
    "itemTimeStamp":string,
    "operationNumber":string,
    "workOrderNumber":string,
    "statusNonNumber":string,
    "statusNotes":string,
    "statusNumber":string
}
export interface Hold{
  "assignedEngineer":string,
  "assignmentEnd": string,
  "assignmentStart":string,
  "dispatchId":string,
  "engineerId":string ,
  "itemTimeStamp":string,
  "operationNumber":string,
  "workOrderNumber":string,
  "statusNonNumber":string,
  "statusNotes":string,
  "statusNumber":string
}

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  recommendStatusCheck = ['UNSC','ASGN','DISP','AKNW','HOLD','ACPT','DECL']
  searchValue : any
  private ordersSelected = new BehaviorSubject([]);
  selectedOrderList = this.ordersSelected.asObservable();
  constructor(private httpService: HttpService) { }

  addselectedOrders(message) {
    this.ordersSelected.next(message)
  } 
  private processTaskListInfo(element,index){
    element["indexValue"] = index + 1;
    // element["appointmentTimeSlot"]= element.appointmentStart && element.appointmentEnd ? moment(element.appointmentStart).format("hh:mma")+'-'+ moment(element.appointmentEnd).format("hh:mma"): null;
     element["crewSize"] = element["crewSize"] ? element["crewSize"].trim() : null;
     element["appointmentStartDate"] = element["appointmentStart"] ? element["appointmentStart"].split(" ")[0] : null;
     element["creationDateTime"] = element["creationDateTime"] ? element["creationDateTime"].split(" ")[0] : null;
     element["escalator"] = element["escalator"] ? "Yes" : "No";
     element["policeEscort"] = element["policeEscort"] ? "Yes" : "No";
     element["specialHandling"] = element["specialHandling"] ? "Yes" : "No";
     element["holidaySupressed"] = element["holidaySupressed"] ? "Restricted" : "Un-Restricted";

     //let balanceSplit = element["balance"] ? element["balance"].split('.') : null;
     element["balance"] = element["balance"] ?  parseFloat(element["balance"]): 0.00
  }
  /**
   * @name getPendingOrders
   * @description Gets the data stream for the pending orders table based on the selected districts or entire data if district is not selected
   * @param list of districts 
   * @param list of contractors
   * @returns observable stream of data  
   */
  getPendingOrders(districts,contractors) {
    let data ={
      districts:districts.length>0?districts:null,
      companies:contractors.length>0?contractors:null
    }
    let url = API_URL.getPendingOrderDetails//.replace("{districts}", districts.length>0?districts:null).replace("{companies}", contractors.length>0?contractors:null);
 //   return this.httpService.apiRequest(url, 'GET');
    return this.httpService.apiRequest(url, 'POST',data).pipe(map((res:any)=>{
      res.forEach((element,index)=>this.processTaskListInfo(element,index))
      return res;
    }));
  }
   /**
   * @name getOpenOrders
   * @description Gets the data stream for the open orders table based on the selected districts or entire data if district is not selected
   * @param list of districts 
   * @returns observable stream of data  
   */
    getOpenOrders(district,fsrData) {
      let url;
      if(fsrData?.personnelNumber){
         url = API_URL.getOpenOrdersWithLatLong.replace("{district}", district).replace("{longitude}", fsrData.longitude).replace("{latitude}", fsrData.latitude)
      } else{
         url = API_URL.getOpenOrders.replace("{district}", district);
      }
     // return this.httpService.apiRequest(url, 'GET');
      return this.httpService.apiRequest(url, 'GET').pipe(map((res:any)=>{
        res.forEach((element,index) => this.processTaskListInfo(element,index))
        return res;
      }));
    }

  

  /**
   * @name getFSRInfo
   * @description Gets the data stream for the fsrs in the gantt chart based on the selected district
   * @param district
   * @returns observable stream of data  
   */
  getFSRInfo(district, date) {
    //console.log(date);
    let accessToken = JSON.parse(window.localStorage['okta-token-storage']);
    
    let url = API_URL.listOfFSRSByDistrict.replace("{district}", district);
    url = url.replace("{date}", date).replace("{supervisorId}",accessToken.idToken.claims.Employee_ID);
    return this.httpService.apiRequest(url, 'GET').pipe(map((res:any)=>{
      
      let data = {
        ungrouped:[],
        grouped:res
      }
      res.forEach(element => {
        element.districtUsers = sortBy(element.districtUsers,["name"]);
       data.ungrouped = data.ungrouped.concat(element.districtUsers);
      })
      return data;
    }));
    // return fsrData;
  }

  getdummyFsrData(){
    return {"personnelNumber":1100523912,"name":"user123","telephone":null,"supervisorNote":"Test test","status":"online","startTime":"07:00","endTime":"18:00","latitude":37.48461600229358,"longitude":-77.56867142545586,"mappingPersonnelNumber":10052391,"userId":"CHOUDHP"};
  }
  /**
   * @name getAssignedOrders
   * @description Gets the data stream for the fsr's assigned orders in the gantt chart based on the selected district
   * @param district
   * @returns observable stream of data  
   */
  getAssignedOrders(district,date,scaffolding?,tab?) {

    if(scaffolding)
    {
    let workOrders=[];
    let status = ["ASGN","COMP","INCM","AKNW","HOLD","ENRT","ONST","CANC","PDISP","DISP","UNSC"];
    let coords=[{lat:37.46463848974578,lng: -77.5780170889795},
    {lat:37.476391671957074,lng: -77.56539326573821},
  {lat:37.48870716637442, lng:-77.54741870937012},
{lat:37.483803491576694, lng:-77.57488452788833},
{lat:37.49524489873166, lng:-77.55548679355984},
{lat:37.493065718196185, lng:-77.56750308916156},
{lat:37.474403881899455,lng: -77.55308353443951},
{lat:37.48135153347707, lng:-77.56029331180054},
{lat:37.48639158227444,lng: -77.58690082349005},
{lat:37.47372270482084,lng: -77.57934772339755},
{lat:37.47276904648087,lng: -77.5432988365924}]
    for(let j=0;j<scaffolding.length;j++)
    {
      let currentTime = moment().set({'hour':9,'minute':0});
    for(let i=0;i<11;i++){
      let obj = {
        "status": status[i],
        "priority":  Math.floor(Math.random()*8)+1,
        "matCodeDesc": "MatcodeDescription",
        "workOrderNumber": Math.floor(100000000 + Math.random() * 500000000),
        "assignmentStart": currentTime.add(5,'minutes').format("YYYY-MM-DD HH:mm:ss"),
        "assignmentEnd": currentTime.add(15,'minutes').format("YYYY-MM-DD HH:mm:ss"),
        "premiseAddress": "1254 Dewy Rise, Indianapolis, OR",
        "latitude": coords[i].lat,
        "longitude": coords[i].lng,
        "assignedTo": scaffolding[j].personnelNumber,
        "taskDuration": 33
          }
      workOrders.push(obj);
        }
      }
      return workOrders;
    }
    else{
         const url = API_URL.getDistrictWorkOrderAndBlockTimeForGantt.replace("{district}",district).replace("{date}",date).replace("{tab}",tab==null?"Pending":tab);
         return this.httpService.apiRequest(url, 'GET');
    }
  }

  /**
 * @name getAssignedOrdersforFsr
 * @description Gets the data stream for the fsr assigned orders 
 * @param fsrid
 * @returns observable stream of data  
 */
   getAssignedOrdersforFsr(fsrid) {
    
     const url = API_URL.getAssignedOrdersForFsr.replace("{personnelNumber}", fsrid);
      return this.httpService.apiRequest(url, 'GET');
    
  }

  getSingleEngineerOrder(employeeId, date) {
    let url = API_URL.getAssignedWorkOrders.replace("{personnelNumber}", employeeId).replace("{date}", date);
    // return this.httpService.apiRequest(url, 'GET');
    return this.httpService.apiRequest(url, 'GET').pipe(map((res:any)=>{
      res.forEach((element,index) => this.processTaskListInfo(element,index))
      return res;
    }));
  }
  /**
   * @name getAllLocationPreferences
   * @description get all the location preferences of a user 
   * @returns observable stream of data 
   */
  getAllLocationPreferences() {
    const token = JSON.parse(localStorage.getItem("okta-token-storage"));
    const url = API_URL.getAllLocationPreferences.replace("{EmployeeID}", token.idToken.claims.Employee_ID);
    return this.httpService.apiRequest(url, 'GET');

  }
  /**
   * @name updateLocationPreferences
   * @description update the location preferences of a user 
   * @param data to be sent 
   * @returns observable stream of data 
   */
  updateLocationPreferences(data) {
    const url = API_URL.updateLocationPreferences;
    return this.httpService.apiRequest(url, 'POST', data);
  }
  /**
   * @name deleteLocationPreferences
   * @description delete the location preferences of a user 
   * @param id to be deleted 
   * @returns observable stream of data 
   */
  deleteLocationPreferences(id) {
    const url = API_URL.deleteLocationPreferences.replace("{perferenceId}", id);
    return this.httpService.apiRequest(url, 'POST');
  }

  /**
  * @name saveNotes
  * @description saves the notes entered for fsr in gantt chart
  * @param data to be sent 
  * @returns observable stream of data 
  */
  saveNotes(data) {
    const url = API_URL.updateSupervisorNote;
    return this.httpService.apiRequest(url, 'POST', data);
  }

  /**
    * @name getRecommendEmployees
    * @description Gets the Employee Data for district recommended
    * @param districts optional
    * @returns observable stream of data  
    */
  getRecommendEmployees(district) {
    let url = API_URL.getEmployeesRecommended.replace("{district}", district);
    return this.httpService.apiRequest(url, 'GET');
  }
/**
    * @name getWorkOrdersOnSearch
    * @description get the details of the given wo
    * @param woNumber 
    * @returns observable stream of data  
    */
  getWorkOrdersOnSearch(woNumber){
    let url = API_URL.searchWorkOrder.replace("{workOrderNumber}", woNumber);
    return this.httpService.apiRequest(url, 'GET').pipe(map((res:any)=>{
      res.forEach((element,index)=>this.processTaskListInfo(element,index))
      return res;
    }));
  }

  /**
    * @name recommendWorkOrders
    * @description recommends a list of work orders
    * @param list the lis of work orders
    * @param isRecommend wither the api is recommend or assign
    * @returns observable stream of data  
    */
  recommendWorkOrders(list:Recommend[],isRecommend){
    if(isRecommend){
      let url = API_URL.recommedWorkOrders
    return this.httpService.apiRequest(url, 'POST',list);
    }
    else{
      let url = API_URL.assignWorkOrders
    return this.httpService.apiRequest(url, 'POST',list);
    }
  }
   /**
    * @name soStatusUpdateWorkOrder removed
    * @description list of orders to be unsceduled or put on hold
    * @param list the lis of work orders
    * @returns observable stream of data  
    */
    // soStatusUpdateWorkOrder(list:SoStatusUpdate[]){
    //     let url = API_URL.soStatusUpdateWorkOrder;
    //     let accessToken = JSON.parse(window.localStorage['okta-token-storage']);
    //     const headerOptions = {
    //       "Authorization":accessToken.accessToken.accessToken,
    //       "APIKey":environment.w1vApi.apikey
    //     };
    //     const headers = new HttpHeaders(headerOptions);
    //   return this.httpService.apiRequest(url,'POST',{data:list},{headers:headers});
    // }

     /**
    * @name getscheduledworkOrders
    * @description list of orders to be shown in scheduled orders page
    * @param list of districts and date
    * @returns observable stream of data  
    */
      getscheduledworkOrders(districts,companies,date,dateType,role){
        let url = role=="monitor"?API_URL.getScheduledWorkOrdersNew:API_URL.getSupervisorOrders;
        url=url.replace("{districts}",districts.length>0?districts:null);
        url=url.replace("{companies}",companies.length>0?companies:null);
        url=url.replace("{date}",date);
        url=url.replace("{dateType}",dateType) 
        //return this.httpService.apiRequest(url, 'GET');
        return this.httpService.apiRequest(url, 'GET').pipe(map((res:any)=>{
          res.forEach((element,index) => this.processTaskListInfo(element,index))
          return res;
        }));

    }
    addBlockTime(data) {
      const url = API_URL.createBlockTime;
      return this.httpService.apiRequest(url, 'POST', data);
    }
    updateDeleteBlockTime(data){
      const url = API_URL.updateOrDeleteBlockTime;
      return this.httpService.apiRequest(url, 'POST', data);
    }

   getOpenOrderControls(state,district){
    const token = JSON.parse(localStorage.getItem("okta-token-storage"));
     const url =  API_URL.getOpenOrderCriteriasByDistrict
     .replace("{supervisorId}", token.idToken.claims.Employee_ID)
     .replace("{state}", state)
     .replace("{district}",district)
     return this.httpService.apiRequest(url, 'GET');
   }
   updateOpenOrderControls(data){
    const token = JSON.parse(localStorage.getItem("okta-token-storage"));
     const url =  API_URL.updateOpenOrderCriteriasByDistrict
     .replace("{supervisorId}", token.idToken.claims.Employee_ID);
     return this.httpService.apiRequest(url, 'POST',data);
   }
   getOpenOrderByControls(data){
    const token = JSON.parse(localStorage.getItem("okta-token-storage"));
     const url =  API_URL.getOpenOrderByCriteria
     .replace("{supervisorId}", token.idToken.claims.Employee_ID);
     return this.httpService.apiRequest(url, 'POST',data);
   }

   updateFsrRadius(fsrid,radius){
    const url =  API_URL.updateFsrRadius.replace("{fsrId}",fsrid).replace("{radius}",radius);
    return this.httpService.apiRequest(url, 'POST');
   }
  /**Pinned Orders*/
   postPinOrder(orders,flag){
    const token = JSON.parse(localStorage.getItem("okta-token-storage"));
    const url =  API_URL.updatePinStatus.replace("{orders}", orders).replace("{flag}",flag)
    return this.httpService.apiRequest(url, 'POST');
  }
  
  getWorkOrderDetails(workOrderNumber: String) {
    const url = API_URL.getWorkOrderDetails + workOrderNumber;
    return this.httpService.apiRequest(url,'GET');
  }
  getWorkOrderMinDetails(workOrderNumber) {
    const url = API_URL.getWorkOrderMinDetails + workOrderNumber;
    return this.httpService.apiRequest(url, 'GET');
  }
  getServiceOrdersForPremise(premiseId: string) {
    const url = API_URL.getBusinessPartnerInfo + premiseId;
    return this.httpService.apiRequest(url, 'GET');
  }
  getBusinessPartnerInfo(workOrderNumber: String) {
    const url = API_URL.getServiceOrderInfo + workOrderNumber;
    return this.httpService.apiRequest(url, 'GET')
  }
  getInteractionRecordsInfo(workOrderNumber: string) {
    const url = API_URL.getInteractionRecordsInfo + workOrderNumber;
    return this.httpService.apiRequest(url, 'GET')
  }
  tapImage(premiseId) {
    const url = API_URL.tapImage + premiseId;
    return this.httpService.apiRequest(url, 'GET')
  }
  getStoredCMSInfo(premiseId) {
    const url = API_URL.getStoredCMSInfo + '?premiseId=' + premiseId;
    return this.httpService.apiRequest(url, 'GET')
  }
  /**
   * @name getCompletionNotes 
   * @param workorderNo
   * @description gets the completion notes of the given work order number
   * @returns oberserable stream 
   */
  getCompletionNotes(workorderNo){
    const url = API_URL.getCompletionNotes.replace('{workordernumber}',workorderNo)
    return this.httpService.apiRequest(url, 'GET')
  }
  /**
    * @name unscheduleWorkOrders
    * @description list of orders to be unsceduled 
    * @param list the lis of work orders
    * @returns observable stream of data  
    */
   unscheduleWorkOrders(list:Unschedule[]){
    let url = API_URL.unscheduleWorkOrder;
  return this.httpService.apiRequest(url,'POST',list);
}
/**
 * @Name getBlockTimeLogs
 * @param fsrID
 * @description gets the block time logs for the given fsrid for 90 days
 * @returns observable stream of data 
 */
getBlockTimeLogs(fsrID){
  let url = API_URL.getTimeBlockLogs.replace('{fsrNumber}',fsrID);
  return this.httpService.apiRequest(url,'GET');
}
/**
    * @name holdWorkOrders
    * @description list of orders to be put on hold 
    * @param list the lis of work orders
    * @returns observable stream of data  
    */
 holdWorkOrders(list:Hold[]){
  let url = API_URL.holdWorkOrders;
return this.httpService.apiRequest(url,'POST',list);
}
/**
    * @name getFsrDetails
    * @description list of orders to be put on hold 
    * @param personnelNumber the lis of work orders
    * @returns observable stream of data  
    */
 getFsrDetails(personnelNumber){
  let url = API_URL.getFsrDetails.replace("{personnelNumber}",personnelNumber);
return this.httpService.apiRequest(url,'GET');
}
/**
 * @name getContartorAppointmentOrders
 * @description gets all the p3 orders for the given companyid in UNSC status
 * @param companyid 
 */
getContartorAppointmentOrders(companyid){
  let url = API_URL.getAppointmnetOrders.replace("{companyid}",companyid.split("-@-")[1]);
return this.httpService.apiRequest(url,'GET').pipe(map((res:any)=>{
  res.data.forEach((element,index)=>this.processTaskListInfo(element,index))
  return res;
}));;
}
/**
 * @name getContartorAppointmentOrdersByDateRange
 * @description gets all the p3 orders for the given companyid in UNSC status for given date range
 * @param companyid 
 * @param startDate
 * @param endDate
 */
getContartorAppointmentOrdersByDateRange(companyid,startDate,endDate){
  let url = API_URL.getAppointmnetOrdersByDateRange.replace("{companyid}",companyid.split("-@-")[1]).replace("{startDate}",startDate).replace("{endDate}",endDate);
return this.httpService.apiRequest(url,'GET').pipe(map((res:any)=>{
  res.data.forEach((element,index)=>this.processTaskListInfo(element,index))
  return res;
}));;
}
/**
 * @name getSlotsForAppointments
 * @description get slots for appointments 
 * @param companyid 
 * 
 */
getSlotsForAppointments(companyid){
  let url = API_URL.getContartorSlots.replace("{companyid}",companyid.split("-@-")[1]);
return this.httpService.apiRequest(url,'GET');
}
/**
    * @name soScheduleWorkOrder removed
    * @description list of orders to be scheduled or resheduled
    * @param list the lis of work orders
    * @returns observable stream of data  
    */
    soStatusUpdateWorkOrder(list:SoScheduleUpdate){
        let url = API_URL.soScheduleUpdates;
        let accessToken = JSON.parse(window.localStorage['okta-token-storage']);
        const headerOptions = {
          "Authorization":accessToken.accessToken.accessToken,
          "APIKey":environment.w1vApi.apikey,
        };
        const headers = new HttpHeaders(headerOptions);
      return this.httpService.apiRequest(url,'POST',list,{headers:headers});
    }
}

