<mat-grid-list cols="5" rowHeight="80vh">
  <mat-grid-tile [colspan]="1" class="order-details">
    <div class="order-detials-wrapper">
      <span>Audit Trial</span>
      <h1>{{ inputData.workOrderNumber }}</h1>
      <h5>Due Date</h5>
      <h4>{{inputData.dueDate ?  (inputData.dueDate|datetimeFormat:"YYYY-MM-DD HH:mm:ss":"MM/DD/YY") : "--"}}</h4>
      <h5>Appoinment Start</h5>
      <h4>{{inputData.appointmentStart ?  (inputData.appointmentStart|datetimeFormat:"YYYY-MM-DD HH:mm:ss":"MM/DD/YY hh:mm A") : "--" }}</h4>
      <h5>Appoinment End</h5>
      <h4>{{inputData.appointmentEnd ?  (inputData.appointmentEnd|datetimeFormat:"YYYY-MM-DD HH:mm:ss":"MM/DD/YY hh:mm A") : "--" }}</h4>
      <h5>Early Start Date</h5>
      <h4>{{inputData.earlyStartDate?  (inputData.earlyStartDate|datetimeFormat:"YYYY-MM-DD HH:mm:ss":"MM/DD/YY") : "--" }}</h4>
      <!-- <button mat-flat-button color="accent" mat-dialog-close>Close</button> -->
    </div>
    
  </mat-grid-tile>
  <mat-grid-tile [colspan]="4">
    <div class="audit-table-container">
      <div class>
        <button mat-icon-button class="close-button" mat-dialog-close>
          <mat-icon class="close-icon" color="primary">close</mat-icon>
      </button>
       </div>
      <table
        full-width-table
        mat-table
        [dataSource]="dataSource"
        matSort
        class="audit-log-table"
      >
        <!-- Edit Date -->
        <!-- <ng-container matColumnDef="timeStamp">
          <th class="thead" mat-header-cell *matHeaderCellDef>Time Stamp</th>
          <td mat-cell *matCellDef="let auditData">
            {{auditData.itemTimestamp ? moment(auditData.itemTimestamp).utc().format("MM/DD/YY hh:mm A") : "--"}}
          </td>
        </ng-container> -->
        <!-- updateTime -->
        <ng-container matColumnDef="updateTime">
          <th class="thead" mat-header-cell *matHeaderCellDef>
            Updated Time (EST)
          </th>
          <td mat-cell *matCellDef="let auditData">
           {{ auditData.updateTime ? moment(auditData.updateTime).tz("America/New_York").format("MM/DD/YYYY HH:mm A") : "--"}}
          </td>
        </ng-container>
        <!-- User ID -->
        <ng-container matColumnDef="userId">
          <th class="thead" mat-header-cell *matHeaderCellDef>User Id</th>
          <td mat-cell *matCellDef="let auditData">{{ auditData.dispatcherId }}</td>
        </ng-container>
        <!-- status -->
        <ng-container matColumnDef="status">
          <th class="thead" mat-header-cell *matHeaderCellDef>
            Status
          </th>
          <td class="engineerModal" mat-cell *matCellDef="let auditData">
            <span class="{{ auditData.status }}">
              {{ auditData.status ? auditData.status : "--" }}</span
            >
          </td>
        </ng-container>
        <!-- startTime -->
        <ng-container matColumnDef="startTime">
          <th class="thead" mat-header-cell *matHeaderCellDef>
            Start Time
          </th>
          <td mat-cell *matCellDef="let auditData">
            <span></span>{{ auditData.assignmentStart ? (auditData.assignmentStart|datetimeFormat:"YYYY-MM-DD HH:mm:ss":"MM/DD/YY hh:mm A") : "--"}}
          </td>
        </ng-container>
        <!-- finishTime -->
        <ng-container matColumnDef="finishTime">
          <th class="thead" mat-header-cell *matHeaderCellDef>
            Finish Time
          </th>
          <td mat-cell *matCellDef="let auditData">
            {{auditData.assignmentEnd ? (auditData.assignmentEnd|datetimeFormat:"YYYY-MM-DD HH:mm:ss":"MM/DD/YY hh:mm A") : "--"}}
          </td>
        </ng-container>
        <!-- assigned -->
        <ng-container matColumnDef="assigned">
          <th class="thead" mat-header-cell *matHeaderCellDef>
            Assigned
          </th>
          <td mat-cell *matCellDef="let auditData">
            {{ auditData.name ? auditData.name : "--" }}
          </td>
        </ng-container>
        <!-- ignoreUpdateWithReason -->
        <ng-container matColumnDef="oldReason">
          <th class="thead" mat-header-cell *matHeaderCellDef>
            Reason
          </th>
          <td mat-cell *matCellDef="let auditData">
            {{ auditData.ignoreUpdateWithReason ? auditData.ignoreUpdateWithReason : "--" }}
          </td>
        </ng-container>
        <tr
          mat-header-row class="header-row"
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </mat-grid-tile>
</mat-grid-list>