import { MiuValidatorComponent } from './../miu-validator/miu-validator.component';
import { UserService } from './../../services/user.service';
import { roleAccessMapping } from './../../app.config';
import { ToastService } from 'src/app/services/toast.service';
import { SharedService } from 'src/app/services/shared.service';
import { environment as environmentConfiguration } from 'src/environments/environment';
import { OktaAuthStateService,OKTA_AUTH } from '@okta/okta-angular';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserSettingsComponent } from '../../components/user-settings/user-settings.component';
import { LegendComponent } from '../legend/legend.component';
import { BannerService } from 'src/app/services/banner.service';
import { AdminService } from 'src/app/services/admin.service';
import { SearchComponent } from 'src/app/module/search/search.component';
import { LoaderService } from 'src/app/services/loader.service';
import OktaAuth from '@okta/okta-auth-js';




@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  
})
export class HeaderComponent implements OnInit {
  roleAccessMapping = roleAccessMapping;
  links = {
    "orders": {lable: 'Pending', tab: 1, icon: 'assets/pending-orders.svg', url: '/orders',notificationCount:0},
    "monitor": {lable: 'Monitor', tab: 2, icon: 'assets/schedule-orders.svg', url: '/monitor',notificationCount:0},
    "contractorAppointment": {lable: 'Con. Appt.', tab: 3, icon: 'assets/contarctor-booking.svg', url: '/contractorAppointment',notificationCount:0},
    "supervisor": {lable: 'Supervisor', tab:4, icon: 'assets/schedule-orders.svg', url: '/supervisor',notificationCount:0},
    "openOrders": {lable: 'Undated', tab:5, icon: 'assets/undated.svg', url: '/openOrders',notificationCount:0},
    "locations":{lable: 'Locations', tab:6, icon: 'assets/map.svg', url: '/locations',notificationCount:0},
    "employees": {lable: 'Employees', tab:7, icon: 'assets/employees.svg', url: '/employees',notificationCount:0},
    "exceptions":{lable: 'Exceptions', tab:8,  icon: 'assets/exceptions.svg', url: '/exceptions',notificationCount:0},
    "holidays":{lable: 'Holidays', tab:9, icon: 'assets/holiday.svg', url: '/holidays',notificationCount:0},
    "capacityCalculator":{lable: 'Capacity', tab:10, icon: 'assets/capacity.svg', url: '/capacityCalculator',notificationCount:0},
    "admin":{lable: 'Admin', tab:11, icon: 'assets/Vector.svg', url: '/admin',notificationCount:0},
    //"support":{lable: 'Support', tab:12, icon: 'assets/Vector.svg', url: '/support',notificationCount:0},
  };

  userName;
  version;
  tabCounts
  role: string;
  isSupportRole:boolean;
  appLinks:any;
  externalLinkLoader = false;
  contractingCompanyCode = null;
  constructor(private oktaAuthService:OktaAuthStateService, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,private matDialog:MatDialog,private sharedService:SharedService,private toastService:ToastService,private userService:UserService, private bannerService:BannerService, private adminService:AdminService,private loaderService:LoaderService) {
    
    this.oktaAuthService.authState$.subscribe((state)=>{
      console.log(state)
      if(state.isAuthenticated){
        this.role = this.userService.getRole();
        this.isSupportRole = this.userService.hasSupportRole();
    this.tabCounts = this.sharedService.getTabsCount().subscribe((data)=>{
      //console.log(data);
      this.bannerService.openAlert(data[1]);
      for (const key in data[0]) {
        this.links[key].notificationCount =  data[0][key];
      }
    },(error)=>{
      this.toastService.error("Something went wrong with the notifications please try refreshing");
    });
    let accessToken = window.localStorage['okta-token-storage']?JSON.parse(window.localStorage['okta-token-storage']):null;
    this.userName = accessToken?accessToken.idToken?.claims.name:"";
    this.version = environmentConfiguration.version;
  }
  });
  
   }

  ngOnInit(): void {

    /** added for search feature to check the contracting company */
    
   
  }

  retrunZero(){
    return 0;
  }
  logout(){
    this.oktaAuth.signOut();
  }
  menuOpened(){
    this.externalLinkLoader = true;
    this.adminService.getAllLinks().subscribe((res) =>{
      this.externalLinkLoader = false;
      this.appLinks= res;
    },(error)=>{
      this.externalLinkLoader = false;
      this.toastService.error("There was an error please try again later");
      console.log("Error from server", error);
    });
  }
  openInNextTab(url){
    window.open(url, 'blank');
  }
  loadLegend(){
    const dialog = this.matDialog.open(LegendComponent, {
      data: {},
      hasBackdrop: false,
      disableClose: true,
      autoFocus: false,
      height: 'auto',
      maxWidth: '80vw'
    });
    dialog.afterClosed().subscribe((result) => {});
  }
  
  loadUserPreferences() {
    const dialog = this.matDialog.open(UserSettingsComponent, {
      data: {},
      panelClass: 'openOrdersRulesFilterDialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      height: 'auto',
      maxWidth: '100vw'
    });
    dialog.afterClosed().subscribe((result) => {
      if (result != null && result.length >= 0) {
        // this.userPreferenceResult = result;
      }
    });
  }
  loadMiuvalidator(){
     const dialog = this.matDialog.open(MiuValidatorComponent, {
      data: {},
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      width: '950px',
      height: '700px',
    });
  }
  onOpenSearch(){
    if(this.userService.getRole()=="CONT_SUPERVISOR")
    {
      this.loaderService.showLoader();
    this.sharedService.getAllStatesAndDistricts(true).subscribe((res)=>{
      console.log(res);
      this.loaderService.hideLoader();
      this.contractingCompanyCode = res[0].districts[0];
      console.log(this.contractingCompanyCode);
      this.openSearchDialog();
    },
    (error)=>{
      this.loaderService.hideLoader();
      this.toastService.error("Could get the company code for contarting company.");
    })
    }else{
      this.openSearchDialog();
    }
    
  }
  openSearchDialog(){
    const dialog = this.matDialog.open(SearchComponent, {
      data: {contractingCompanyCode:this.contractingCompanyCode},
      hasBackdrop: true,
      disableClose: true,
      width: '95vw',
      maxWidth:'95vw',
      //height:"250px",
      position:{top:'60px'}
    });
  }
  }

