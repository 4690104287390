import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private readonly snackBar: MatSnackBar) {}
 /**
  * @name default 
  * @description shows default toast message
  * @param message 
  */
  default(message: string): void {
    this.show(message, {
      duration: 4000,
      panelClass: 'default-toast-overlay',
    });
  }
/**
  * @name info 
  * @description info default toast message
  * @param message 
  */
  info(message: string): void {
    this.show(message, {
      duration: 4000,
      panelClass: 'info-toast-overlay',
    });
  }
/**
  * @name success 
  * @description shows success toast message
  * @param message 
  */
  success(message: string): void {
    this.show(message, {
      duration: 4000,
      panelClass: 'success-toast-overlay',
    });
  }
/**
  * @name warn 
  * @description shows warn toast message
  * @param message 
  */
  warn(message: string): void {
    this.show(message, {
      duration: 4000,
      panelClass: 'warning-toast-overlay',
    });
  }
/**
  * @name error 
  * @description shows error toast message
  * @param message 
  */
  error(message: string): void {
    this.show(message, {
      duration: 4000,
      panelClass: 'error-toast-overlay',
    });
  }
/**
  * @name show 
  * @description shows the configured toast message
  * @param message
  * @param configuration MatSnackBarConfig
  */
  private show(message: string, configuration: MatSnackBarConfig): void {
    configuration.horizontalPosition = 'right';
    configuration.verticalPosition = 'top';
    this.snackBar.open(message, null, configuration);
  }
}
