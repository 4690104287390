import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastService } from 'src/app/services/toast.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import * as config from '../../app.config';
import { UserService } from '../../services/user.service';
import {DataService} from '../../services/notifyChange.service';
@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {


  columns = [];
  newcolstr;
  restrictedfileds;
  columnLabelMapping ={
    'index':'index',
    'Priority':'Priority',
    'State':'State',
    'District':'District',
    'WO#':'WO',
    'MAT Code':'MAT Code',
    'Comments':'Comments',
    'Description':'Description',
    'Due date':'Due date',
    'Balance (amount due)':'Balance',
    'Address':'Address',
    'City':'City',
    'Zip':'Zip',
    'Meter size':'Meter size',
    'MRU':'MRU',
    'Locality':'Locality',
    'Crew size':'Crew size',
    'Escort':'Escort',
    'Escalator':'Escalator',
    'Meter location':'Meter location',
    'Early start date':'Early start date',
    'Appt. date & time':'Appt. time',
    'Appt. date':'Appt. date',
    'Meter #':'Meter #',
    'Status':'Status',
    'Assigned engineer':'Assigned engineer',
    'Created by User ID':'Created by User ID',
    'WO Desc':'WO Desc',
    'Created date & time':'Created date',
    'Created Time':'Created time',
    'Endpoint Type':'Endpoint Type',
    'Pinned':'Pinned',
    'Completion Notes':'Completion Notes',
    'Meter Install Date':'Meter Install Date',
    'Meter Manufacturer':'Meter Manufacturer',
    'BPEM':'BPEM',
    'landlordAllocation':'Landlord',
    'Work Center':'Work Center',
    'jeopardy':'Jeopardy',
    'premiseType':'Premise Type',
    'specialHandling':'Special Handle',
    'holidaySupressed':'Restricted'

    }
    
  constructor(private dataService :DataService,@Inject(MAT_DIALOG_DATA) public detailData: any, public dialogRef: MatDialogRef<UserSettingsComponent>, private toastService: ToastService, private loader: LoaderService, private userService: UserService) { }

  ngOnInit(): void {
    this.newcolstr = config.pendingOrder_Columns;
    this.restrictedfileds = config.RestrictPendingColumns;
    this.loadInitData();

  }
  reset(){
    this.columns=this.userService.loadDefaultData();
    this.columns=this.userService.loadRestricted(this.columns);

  }

  loadInitData() {
    // debugger;
    let employeeid = this.userService.getEmployeeId();
    let self = this;
    this.loader.showLoader();
    this.userService.getUserPreferences(employeeid).subscribe(data => {
      // debugger;
      if (data == null) {
        self.columns=self.userService.loadDefaultData();
      }
      else {
        let dbData = data["userPreference"];
        let arr = dbData.split(",");
        self.columns = arr.map(x => { return { name: x.split("-")[0], selected: JSON.parse(x.split("-")[1]), showAlways: false } });
      }
      self.columns=self.userService.loadRestricted(self.columns);

      self.loader.hideLoader();
    },
      error => {
        console.log("Error in fetching user preferences", error);
      })

  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
  }
  save() {
    // debugger;
    let newatr = this.columns.map(x => { return x.name + "-" + x.selected }).join(",");
    this.loader.showLoader();
    this.userService.saveUserPreferences(this.userService.getEmployeeId(), newatr).subscribe(res => {
      if (res["success"]) {
      //  debugger;
        this.toastService.success("Preferences Saved Successfully");
        this.dataService.changeMessage(newatr);
        this.dialogRef.close(newatr);

      }
      else {
        this.toastService.error("Could not save the user settings");
      }
      this.loader.hideLoader();
    }, error => {
      console.log("error in saving user preference", error);
    });
  }

}