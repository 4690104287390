import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-hold-status-alert',
  templateUrl: './hold-status-alert.component.html',
  styleUrls: ['./hold-status-alert.component.scss']
})
export class HoldStatusAlertComponent implements OnInit {
  holdStatus = this.formBuilder.group({
    otherReason:[''],
    reason:['']
  });
  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<HoldStatusAlertComponent>,private formBuilder:UntypedFormBuilder) { }

  ngOnInit(): void {
    console.log(this.data,this.holdStatus?.value);
    
  }
  onDiaglogAction(){
    console.log(this.data,this.holdStatus?.value);
    this.dialogRef.close(this.holdStatus.value)
  }
  changeReason(value){
    console.log(value);
    if(value !== 'Other'){
      this.holdStatus.controls["otherReason"].clearValidators();
      this.holdStatus.controls["otherReason"].updateValueAndValidity();
    }
  }
 
}
