import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'datetimeFormat'
})
export class DatetimeFormatPipe implements PipeTransform {

  transform(date,inputFormat:string,outputformat:string): any {
    return moment(date,inputFormat).format(outputformat);
  }

}
