<div mat-dialog-title class="title">
    <div class="left-content">
      <div class='filters'>
        <input autocomplete="false" matInput placeholder="Search Orders" [(ngModel)]="searchValue" #input
          (keypress)="restrictAlphabets($event)">
        <button mat-icon-button class='icon-holder' (click)="onApplyFilter(searchValue)" matSuffix>
          <i class="material-icons">search</i>
        </button>
      </div>
      <div class="action-buttons">
        <button [disabled]="!criteria['u']" (click)="unschedule(inputData.orders)" class="accent btn"
          mat-flat-button>Unschedule</button>
        <button [disabled]="!criteria['r']" (click)="openRecommend(inputData.orders)" class="accent-darker btn"
          mat-flat-button>Recommend</button>
          <button *ngIf="userRole=='CONT_SUPERVISOR'" [disabled]="!criteria['s']" (click)="openSchedule(inputData.orders)" class="accent-darker btn"
          mat-flat-button>Schedule/ReSchedule</button>
       
          <mat-checkbox color="accent"  [checked]="timer.isActive" class="timer" (change)="onTimerChange($event)" >{{timer.isActive?"Refreshing in "+timer.interval+" Sec":"Auto-Refresh"}}</mat-checkbox>
      </div>
    </div>
    <div class="right-content">
      <i [matDialogClose] class="material-icons" (click)="close()">
        close
      </i>
    </div>
  </div>
  <mat-dialog-content>
      <app-orders-grid *ngIf="inputData.orders.length>0" [orders]="inputData.orders" (reloadData)="loadComponentsData()" [engineerData]="inputData.engineerData" class="orders-grid-component"
        [actionButtons]="criteria['h']" [engineerModal]=true currentTab="search"></app-orders-grid>
    <div *ngIf="inputData.orders.length==0"  class="no-order">
      <i class="material-icons">
        find_in_page
      </i>
        <p>Order Not Found</p>
    </div>
  </mat-dialog-content>