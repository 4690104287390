import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit {
  status = [
    {
    label:"Assigned",
    class:"ASGN"
  },
  {
    label:"Dispatched",
    class:"DISP"
  },
  {
    label:"Acknowledged",
    class:"AKNW"
  },
  {
    label:"Enroute",
    class:"ENRT"
  },
  {
    label:"Onsite",
    class:"ONST"
  },
  {
    label:"On Hold",
    class:"HOLD"
  },
  {
    label:"Complete",
    class:"COMP"
  },
  {
    label:"Incomplete",
    class:"INCM"
  },
  {
    label:"Blocked",
    class:"blocked"
  },
  {
    label:"Cancelled",
    class:"CANC"
  },
  {
    label:"PECA",
    class:"PECA"
  },
  {
    label:"TECO",
    class:"TECO"
  },
  {
    label:"Declined",
    class:"DECL"
  },
  {
    label:"Pre Dispatch",
    class:"PRDS"
  },
  {
    label:"Unscheduled",
    class:"UNSC"
  },
  {
    label:"Accept",
    class:"ACPT"
  }
];

priority = [
  {
    label:"Priority 1",
    class:"priority-1"
  },
  {
    label:"Priority 2",
    class:"priority-2"
  },
  {
    label:"Priority 3",
    class:"priority-3"
  },
  {
    label:"Priority 4-8",
    class:"priority-4"
  }
];
mapPins=[
  {
    label:"Unassigned Work Order Unselected",
    image:"../assets/icons/map-pin.svg"
  },
  {
    label:"Unassigned Work Order Selected",
    image:"../assets/icons/highlight-map-pin.svg"
  },
  {
    label:"FSR Unselected",
    image:"../assets/icons/map-truck-icon.svg"
  },
  {
    label:"FSR Selected",
    image:"../assets/icons/map-truck-icon-selected.svg"
  }
];
orderTypes=[
  {
    label:"Emergency",
    image:"../assets/icons/ordertype/emergency.svg",
    
  },
  {
    label:"Same Day",
    image:"../assets/icons/ordertype/same_day_order.svg",
   
  },
  {
    label:"All Day Apt.",
    image:"../assets/icons/ordertype/all_day_apt.svg",
    
  },
  {
    label:"3 Hour Apt.",
    image:"../assets/icons/ordertype/3_hour_apt.svg",
   
  },
  {
    label:"Pinned Order",
    image:"../assets/icons/ordertype/pinned_icon.svg",
   
  },
  {
    label:"Undated Expiring",
    image:"../assets/icons/ordertype/pinned_order.svg",
   
  }
];
fsrStatus=[
  {
    label:"Available / Working Orders",
    class:"online",
    icon:"check"
  },
  {
    label:"Unavailable",
    class:"inactive",
    icon:"more_horiz"
  },
  {
    label:"Sign Off / Logged Off",
    class:"offline",
    icon:"remove"
  },
  
];

  constructor() { }

  ngOnInit(): void {
  }
}
