import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import {
  catchError,
} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService } from './loader.service';
import { AlertsComponent } from 'src/app/components/alerts/alerts.component';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
 

  constructor(private http: HttpClient,@Inject(OKTA_AUTH) private oktaAuth: OktaAuth,private matDialog: MatDialog,public loader: LoaderService) { }

  
  getDefaultHeaders():HttpHeaders {
    let accessToken = JSON.parse(window.localStorage['okta-token-storage']);
    const headerOptions = {
      //"Authorization":"Basic dXNlcjp1c2Vy",
      "Authorization":"Bearer "+accessToken.accessToken.accessToken,
    };
    return new HttpHeaders(headerOptions);
  }

  apiRequest(url,method,data?,options?){
    
    let reqOptions ={};
    if(options)
    {
      reqOptions = options;
    }
    else{
      reqOptions["headers"] = this.getDefaultHeaders();
    }
    if(data)
    {
      reqOptions["body"] = data;
    }
    const api = this.http.request(method,url,reqOptions).pipe(
      catchError((error)=>this.handleError(error))
    );
    return api;
  }

  handleError(error) {
    let errorMessage = '';
    switch (error.status) {
      case 401:
        //console.log("its here",this);
        this.loader.hideLoader();
        const dialog = this.matDialog.open(AlertsComponent, {
          data: {
            title: "Session Expired",
            message: "Your session has expired",
            type: 'alert',
            buttonPositiveText: 'OK',
            isCloseButtonHidden:true
          },
          
        });
        dialog.afterClosed().subscribe((result) => {
          this.oktaAuth.signOut();
        })
        break;
      default:
        
        break;
    }
    return throwError(error);
  }
}
