<div mat-dialog-title>
    <div class="headertitle">Settings</div>
    <button mat-icon-button class="close-button headerclose" mat-dialog-close>
      <mat-icon class="close-icon " color="primary">close</mat-icon>
    </button>
    
   <h2>Column Order</h2>
   <h4>Drag columns to reorder</h4>
  </div>
  <!--Main dialog Content-->
  <div class="contentdiv">
    <mat-dialog-content class="dialog-container">
        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
            <div class="example-box"  *ngFor="let col of columns" cdkDrag  [cdkDragDisabled]="col.name == 'index' ? true : false">
              <div class="example-custom-placeholder" *cdkDragPlaceholder></div>

              <mat-checkbox class="example-margin" [disabled]="col.showAlways" [(ngModel)]="col.selected" [checked]="col.selected">  {{columnLabelMapping[col.name]}}</mat-checkbox>
              <div class="menu-icon" *ngIf="col.name != 'index'">
                <span class="line"></span>
                <span class="line"></span>
                <span class="line"></span>
            </div>
            </div>
          </div>
      
      </mat-dialog-content>
      <button mat-flat-button  class="reset" (click)="reset()">Reset to Default Order</button>

  </div>
 
  <!--Action buttons-->

  <mat-dialog-actions>

    <button  mat-stroked-button color="primary" class="bottom-btn cancel" mat-dialog-close>Cancel</button>
    <button mat-flat-button color="warn" (click)="save()" class="bottom-btn save">Save</button>
  </mat-dialog-actions>
  