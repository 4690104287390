<div [ngClass]="{'modal_table': engineerModal, 'orders_table_container' : !engineerModal,'searchtab': currentTab == 'search','schedule-model': currentTab == 'scheduleModel'}" (scroll)="onScroll($event)" #container>
        <table mat-table [dataSource]="dataSource" matSort   class='orders_table' cdkDropList #tabledata
        cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
        <ng-container matColumnDef="index" [sticky]="true">
            <th mat-header-cell cdkDrag *matHeaderCellDef></th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)" mat-cell *matCellDef="let data"> {{data["indexValue"]}} </td>
        </ng-container>
        <ng-container matColumnDef="WO#">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="workOrderNumber"> WO# </th> -->
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['workOrderNumber']).length > 0 }" class="filtersTabs" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('workOrderNumber')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="workOrderNumber">WO#</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'workOrderNumber',type:'search'}"></ng-template>
                </mat-menu>
            </th>
            <td   (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)" mat-cell *matCellDef="let data "> {{data["workOrderNumber"] ? data["workOrderNumber"] : "--"}} </td>
        </ng-container>
        <ng-container matColumnDef="MAT Code">
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['matCode']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('matCode')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="matCode">MAT Code</span></span>
                <mat-menu #menu="matMenu">
                 <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'matCode',type:'dropdown'}"></ng-template>
                </mat-menu>


            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)" mat-cell *matCellDef="let data "> {{data["matCode"] ? data["matCode"] : "--"}} </td>
        </ng-container>
        <ng-container matColumnDef="Description">
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['matCodeDescription']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('matCodeDescription')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="matCodeDescription">Description</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'matCodeDescription',type:'dropdown'}"></ng-template> 
                </mat-menu>
            </th>
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="matCodeDescription"> Description </th> -->
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)" mat-cell *matCellDef="let data "> {{data.matCodeDescription}} </td>
        </ng-container>
        <ng-container matColumnDef="Due date">
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['dueDate']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon"  (click)="onClickFilter('dueDate')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="dueDate">Due Date</span></span>
                <mat-menu #menu="matMenu">
    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'dueDate',type:'dropdown',date:true}"></ng-template>


                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)" mat-cell *matCellDef="let data "> {{data.dueDate ? (data.dueDate|datetimeFormat:"YYYY-MM-DD hh:mm:ss":"MM/DD/YYYY") : "--"}} </td>
        </ng-container>
        <ng-container matColumnDef="Balance (amount due)">
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['balance']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon"  (click)="onClickFilter('balance')"> <mat-icon #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="balance">Balance</span></span>
                <mat-menu #menu="matMenu"  class="balance">
                 <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'balance',type:'range'}"></ng-template>

                    <!-- <mat-selection-list [(ngModel)]='filters["balance"]' (selectionChange)="onFilterChange()">
                        <mat-list-option  class ="filtersTabs" checkboxPosition="before" *ngFor="let value of Array.from(filteredDataSet['balance'])" [value]="value">
                            {{value}}
                        </mat-list-option>
                    </mat-selection-list> -->
                </mat-menu>
            </th>
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="balance"> Balance </th> -->
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)" mat-cell *matCellDef="let data "> {{data.balance|currency}} </td>
        </ng-container>
        <ng-container matColumnDef="State">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="state"> State </th> -->
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['state']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('state')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="state">State</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'state',type:'dropdown'}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)" mat-cell *matCellDef="let data "> {{data.state ? data.state : "--"}} </td>
        </ng-container>
        <ng-container matColumnDef="District">
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['district']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('district')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="district">District</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'district',type:'dropdown'}"></ng-template>
                </mat-menu>

            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)" mat-cell *matCellDef="let data "> {{data["district"] ? data["district"] : "--"}} </td>
        </ng-container>
        <!-- <ng-container matColumnDef="District">
            <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="balance"> District </th>
            <td mat-cell *matCellDef="let data "> {{data.district ? data.district : "--"}} </td>
        </ng-container> -->
        <ng-container matColumnDef="Address">
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['houseAddress']).length > 0 }" class="filtersTabs" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('houseAddress')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="houseAddress">Address</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'houseAddress',type:'search'}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)" class="address-cell" mat-cell *matCellDef="let data "> {{data.houseAddress ? data.houseAddress : "--"}} </td>
        </ng-container>
        <ng-container matColumnDef="City">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="city"> City </th> -->
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['city']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('city')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="city">City</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'city',type:'dropdown'}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)" class="address-cell" mat-cell *matCellDef="let data "> {{data.city ? data.city : "--"}} </td>
        </ng-container>
        <ng-container matColumnDef="Zip">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="zip"> Zip Code </th> -->
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['zip']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('zip')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="zip">Zip Code</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'zip',type:'dropdown'}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"  mat-cell *matCellDef="let data "> {{data.zip ? data.zip : "--"}} </td>
        </ng-container>
        <ng-container matColumnDef="Meter size">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="meterSize"> Meter Size </th> -->
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['meterSize']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('meterSize')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="meterSize">Meter Size</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'meterSize',type:'dropdown'}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"  mat-cell *matCellDef="let data "> {{data.meterSize ? data.meterSize: "--"}} </td>
        </ng-container>
        <ng-container matColumnDef="MRU">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="meterReadingUnit"> MRU# </th> -->
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['meterReadingUnit']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('meterReadingUnit')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="meterReadingUnit">MRU#</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'meterReadingUnit',type:'dropdown'}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"  mat-cell *matCellDef="let data "> {{data.meterReadingUnit ? data.meterReadingUnit : "--"}} </td>
        </ng-container>
        <ng-container matColumnDef="Locality">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="locality"> Locality </th> -->
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['locality']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('locality')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="locality">Locality</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'locality',type:'dropdown'}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"  mat-cell *matCellDef="let data "> {{data.locality ? data.locality : "--"}} </td>
        </ng-container>
        <ng-container matColumnDef="Crew size">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="crewSize"> Crew Size </th> -->
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['crewSize']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('crewSize')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="crewSize">Crew Size</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'crewSize',type:'dropdown'}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"  mat-cell *matCellDef="let data "> {{data["crewSize"] ? data["crewSize"] : "--"}} </td>
        </ng-container>
        <ng-container matColumnDef="Escort">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="policeEscort"> Police Escort </th> -->
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['policeEscort']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('policeEscort')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="policeEscort">Police Escort</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'policeEscort',type:'dropdown'}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)" [ngClass]="data.policeEscort=='Yes'?'red-color':''" mat-cell *matCellDef="let data "> {{data.policeEscort}} </td>
        </ng-container>
        <ng-container matColumnDef="Escalator">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="escalator"> Escalator </th> -->
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['escalator']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('escalator')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="escalator">Escalator</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'escalator',type:'dropdown'}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"  mat-cell *matCellDef="let data "> {{data.escalator}} </td>
        </ng-container>
        <ng-container matColumnDef="Meter location">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="meterLocation"> Meter Location  </th> -->
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['meterLocation']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('meterLocation')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="meterLocation">Meter Location</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'meterLocation',type:'dropdown'}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"  mat-cell *matCellDef="let data "> {{data.meterLocation ? data.meterLocation.split(' - ')[0] : "--"}} </td>
        </ng-container>
        <ng-container matColumnDef="Priority">
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['priority']).length > 0 }" mat-header-cell   cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('priority')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="priority">Priority</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'priority',type:'dropdown'}"></ng-template>
                </mat-menu>

            </th>
            <td mat-cell  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)" *matCellDef="let data ">
                <span class="priority priority-{{data.priority}}">Priority {{data.priority ? data.priority : "--"}}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="Early start date">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="earlyStartDate"> Early Start Date </th> -->
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['earlyStartDate']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('earlyStartDate')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="earlyStartDate">Early Start Date</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'earlyStartDate',type:'dropdown',date:true}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"  mat-cell *matCellDef="let data "> {{data["earlyStartDate"] ? (data["earlyStartDate"]|datetimeFormat:"YYYY-MM-DD hh:mm:ss":"MM/DD/YYYY") : "--"}} </td>
        </ng-container>
        <ng-container matColumnDef="Appt. date & time">
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['appointmentTimeSlot']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('appointmentTimeSlot')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="appointmentTimeSlot">Appt Time</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'appointmentTimeSlot',type:'dropdown'}"></ng-template>
                </mat-menu>
            </th>
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="appointmentStart"> Appt Date/Time </th> -->
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"  mat-cell *matCellDef="let data ">
                    {{data.appointmentTimeSlot ? data.appointmentTimeSlot : "--"}}
            </td>
        </ng-container>
        <ng-container matColumnDef="Appt. date">
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['appointmentStartDate']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('appointmentStartDate')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="appointmentStartDate">Appt Date</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'appointmentStartDate',type:'dropdown',date:true}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"  mat-cell *matCellDef="let data ">
                    {{data.appointmentStartDate ? (data.appointmentStartDate|datetimeFormat:"YYYY-MM-DD hh:mm:ss":"MM/DD/YYYY") : "--"}}
            </td>
        </ng-container>
        <ng-container matColumnDef="Meter #">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="meterSerialNumber"> Meter# </th> -->
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['meterSerialNumber']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('meterSerialNumber')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="meterSerialNumber">Meter#</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'meterSerialNumber',type:'search'}"></ng-template>
                </mat-menu>

            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"  mat-cell *matCellDef="let data "> {{data["meterSerialNumber"] ? data["meterSerialNumber"] : "--"}} </td>
        </ng-container>
        <ng-container matColumnDef="Status">
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['status']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('status')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="status">Status</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'status',type:'dropdown'}"></ng-template>
                </mat-menu>

            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)" mat-cell *matCellDef="let data "><span [matTooltipDisabled]="!(data.status=='HOLD')" [matTooltip]="data['comments']" matTooltipPosition="above" matTooltipClass="tooltip tooltip-content hold-tooltip" class="{{data.status}}"> {{data["status"] ? data["status"] : "--"}} </span> </td>
        <!-- </ng-container> -->

        </ng-container>
        <ng-container matColumnDef="Assigned engineer">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="name"> Assigned engineer </th> -->
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['name']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('name')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="name">Assigned engineer</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'name',type:'dropdown'}"></ng-template>
                </mat-menu>
            </th>
            <td (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"  mat-cell *matCellDef="let data "> {{data["name"] ? data["name"] : "--"}} </td>
        </ng-container>
        <ng-container matColumnDef="Created by User ID">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="createdBy"> Created By </th> -->
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['createdBy']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('createdBy')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="createdBy">Created By</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'createdBy',type:'search'}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"  mat-cell *matCellDef="let data "> {{data["createdBy"] ? data["createdBy"] : "--"}} </td>
        </ng-container>
        <ng-container matColumnDef="Created date & time">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="creationDateTime"> Created Date/Time </th> -->
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['creationDateTime']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('creationDateTime')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="creationDateTime">Created Date</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'creationDateTime',type:'dropdown',date:false}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"   mat-cell *matCellDef="let data "><span *ngIf="data.creationDateTime">{{data.creationDateTime|datetimeFormat:"YYYY-MM-DD":"MM/DD/YYYY"}} </span><span *ngIf="!data.creationDateTime" #invalid>--</span> </td>
        </ng-container>
        <ng-container matColumnDef="Created Time">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="creationDateTime"> Created Date/Time </th> -->
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['creationTime']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('creationTime')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="creationTime">Created Time</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'creationTime',type:'dropdown',date:false}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"   mat-cell *matCellDef="let data "><span *ngIf="data.creationTime">{{data.creationTime}}  </span><span *ngIf="!data.creationTime" #invalid>--</span> </td>
        </ng-container>
        <ng-container matColumnDef="Comments">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="longText">Comments</th> -->
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['longText']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('longText')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="longText">Comments</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'longText',type:'search'}"></ng-template>
                   
                </mat-menu>
            </th>
            <td mat-cell  *matCellDef="let data ">  
                <button class="icon-Btn" mat-icon-button (click)="showComments(data['longText'])" color="accent" aria-label="Example icon button with a menu icon">
                    <mat-icon>comment</mat-icon>
                  </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="Completion Notes">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="longText">Comments</th> -->
            <th class="stckyHeader" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span >Completion Notes</span>
            </th>
            <td mat-cell  *matCellDef="let data ">  
                <button class="icon-Btn" mat-icon-button (click)="showCompletionNotes(data['workOrderNumber'])" color="accent" aria-label="Example icon button with a menu icon">
                    <mat-icon>description</mat-icon>
                  </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="holidaySupressed">
                       
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['holidaySupressed']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('holidaySupressed')">
                     <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon>
                    <span mat-sort-header="holidaySupressed">Restricted</span>
                </span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'holidaySupressed',type:'dropdown'}"></ng-template>
                </mat-menu>
            </th>
            
            <td mat-cell *matCellDef="let data">
                <mat-icon  class="holiday-icon" [matTooltipDisabled]="(data.holidaySupressed !=='Restricted')"
                    [matTooltip]="['This order falls on a day it shouldn’t be recommended']"  matTooltipPosition="above"
                    matTooltipClass="tooltip tooltip-content holidaySupressed-tooltip" *ngIf="data.holidaySupressed=='Restricted'">calendar_month
                </mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="WO Desc">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="woDesc">WO Desc </th> -->
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['woDesc']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('woDesc')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="woDesc">WO Desc</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'woDesc',type:'dropdown'}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"  mat-cell *matCellDef="let data "> {{data["woDesc"] ? data["woDesc"] : "--"}} </td>
        </ng-container>
        <ng-container matColumnDef="Endpoint Type">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="woDesc">WO Desc </th> -->
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['miu1EndPoint']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('miu1EndPoint')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="miu1EndPoint">Endpoint Type</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'miu1EndPoint',type:'dropdown'}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"  mat-cell *matCellDef="let data "> {{data["miu1EndPoint"] ? data["miu1EndPoint"] : "--"}} </td>
        </ng-container>
        <ng-container matColumnDef="Pinned">
            <!-- <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header="woDesc">WO Desc </th> -->
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['pinnedFlag']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('pinnedFlag')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="pinnedFlag">Pinned</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'pinnedFlag',type:'dropdown',convertBoolean:true}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"  mat-cell *matCellDef="let data "> {{data["pinnedFlag"] ? "Yes" : "No"}} </td>
        </ng-container>
        <ng-container matColumnDef="Meter Install Date">
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['meterInstallDate']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('meterInstallDate')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="meterInstallDate">Meter Install</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'meterInstallDate',type:'dropdown',date:true}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"   mat-cell *matCellDef="let data "><span *ngIf="data.meterInstallDate">{{data.meterInstallDate|datetimeFormat:"YYYY-MM-DD":"MM/DD/YYYY"}} </span><span *ngIf="!data.meterInstallDate" #invalid>--</span> </td>
        </ng-container>
        
        <ng-container matColumnDef="Meter Manufacturer">
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['meterManufacturer']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('meterManufacturer')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="meterManufacturer">Meter Manufacturer</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'meterManufacturer',type:'dropdown'}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"   mat-cell *matCellDef="let data "><span *ngIf="data.meterManufacturer">{{data.meterManufacturer}} </span><span *ngIf="!data.meterManufacturer" #invalid>--</span> </td>
        </ng-container>
        
        <ng-container matColumnDef="BPEM">
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['bpemCounter']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('bpemCounter')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="bpemCounter">BPEM</span></span>
                <mat-menu #menu="matMenu" class="balance">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'bpemCounter',type:'range'}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"   mat-cell *matCellDef="let data "><span *ngIf="data.bpemCounter">{{data.bpemCounter}} </span><span *ngIf="!data.bpemCounter" #invalid>--</span> </td>
        </ng-container>

        <ng-container matColumnDef="landlordAllocation">
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['landlordAllocation']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('landlordAllocation')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="landlordAllocation">Landlord</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'landlordAllocation',type:'dropdown'}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"   mat-cell *matCellDef="let data "><span>{{data["landlordAllocation"]? data["landlordAllocation"] : "--"}}</span></td>
        </ng-container>

        <ng-container matColumnDef="Work Center">
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['workCenter']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('workCenter')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="workCenter">Work Center</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'workCenter',type:'dropdown'}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"  mat-cell *matCellDef="let data "> {{data["workCenter"] ? data["workCenter"] : "--"}} </td>
        </ng-container>

        <ng-container matColumnDef="jeopardy">
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['jeopardy']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('jeopardy')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="jeopardy">Jeopardy</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'jeopardy',type:'dropdown'}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"   mat-cell *matCellDef="let data "><span>{{data["jeopardy"]? data["jeopardy"] : "--"}}</span></td>
        </ng-container>

        <ng-container matColumnDef="premiseType">
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['premiseType']).length > 0 }" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('premiseType')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="premiseType">Premise Type</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'premiseType',type:'dropdown'}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"   mat-cell *matCellDef="let data "><span>{{data["premiseType"]? data["premiseType"] : "--"}}</span></td>
        </ng-container>
        <ng-container matColumnDef="specialHandling">
            <th class="stckyHeader" [ngClass]="{'activeFilter' : Array.from(filters['specialHandling']).length > 0}" mat-header-cell  cdkDrag *matHeaderCellDef>
                <span class="filter-icon" (click)="onClickFilter('specialHandling')"> <mat-icon [matMenuTriggerFor]="menu">filter_alt</mat-icon><span mat-sort-header="specialHandling">Special Handle</span></span>
                <mat-menu #menu="matMenu">
                    <ng-template *ngTemplateOutlet="filterTest; context: {filterColumn:'specialHandling',type:'dropdown'}"></ng-template>
                </mat-menu>
            </th>
            <td  (click)="(statusActionMapping[data.status].unschedule || statusActionMapping[data.status].recommend) && onSelectRow(data)"   mat-cell *matCellDef="let data "><span>{{data["specialHandling"]? data["specialHandling"] : '--'}}</span></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
        <tr (contextmenu)="onRightClick($event,row)" [draggable]="isDraggable && !readOnly"  mat-row [ngClass]="row && row.active && selectedOrder.length > 0 && !readOnly && (statusActionMapping[row.status]?.unschedule || statusActionMapping[row.status]?.recommend) ? 'row-is-clicked' : row && engineerModal && !statusActionMapping[row.status]?.unschedule && !statusActionMapping[row.status]?.recommend ? 'disabledRows':''" [class.row-is-clicked]="row && !readOnly && (statusActionMapping[row.status]?.unschedule || statusActionMapping[row.status]?.recommend) ? isRowSeleted(selectedOrder,row) : ''"
            *matRowDef="let row; columns: displayedColumns;" (dragstart)="handleDragStart($event,row)"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">
                <!-- <span *ngIf="searchValue">No data matching with the search.</span> -->
                <span *ngIf="orders.length == 0">No Work Orders</span>
                 <span *ngIf="searchValue && previousData!=null && dataSource.data.length==0 && !engineerModal && !readOnly">No Records found for the search value</span> 
            </td>
        </tr>
    </table>
</div>
<mat-paginator *ngIf="currentTab!='search'" [ngClass]="engineerModal ? 'modal_paginator paginator-container' : 'paginator paginator-container'"[pageSize]="gridPageSize" [hidePageSize]=true [showFirstLastButtons]=true
    (page)="onPageChange($event)">
</mat-paginator>

<div class="container"style="visibility:hidden;position: fixed;" [style.left]="menuTopLeftPosition.x" [style.top]="menuTopLeftPosition.y" [matMenuTriggerFor]="rightMenu"></div> 
<!--RIGHT CLICK MENU-->
<mat-menu class="mat-menu-hold dark-menu" #rightMenu="matMenu"> 
  <ng-template matMenuContent let-item="item"> 
    <button [cdkCopyToClipboard]="item.workOrderNumber" (cdkCopyToClipboardCopied)="rightMenuActions($event,'copy',null)"  mat-menu-item><mat-icon>content_copy</mat-icon> Copy WO Number</button> 
    
    <button (click)="rightMenuActions($event,'audit',item.workOrderNumber)"  mat-menu-item>
        <mat-icon>history</mat-icon>
        Order History
    </button>
    <button (click)="rightMenuActions($event,'workAtAGlance',item.workOrderNumber)"  mat-menu-item>
        <mat-icon>grid_view</mat-icon>
        Work details
    </button>
    
    <button *ngIf="actionButtons && currentTab !='search'" class="hold" [ngClass]="!statusActionMapping[item.status].hold ? 'disable' : ''" (click)="statusActionMapping[item.status].hold ? rightMenuActions($event,'putOnHold',item) : false" mat-menu-item> <mat-icon >pause_circle_filled</mat-icon>Put on Hold</button> 
    <button *ngIf="currentTab =='search'" class="hold" [ngClass]="!actionButtons ? 'disable' : ''" (click)="actionButtons ? rightMenuActions($event,'putOnHold',item) : false" mat-menu-item> <mat-icon >pause_circle_filled</mat-icon>Put on Hold</button> 
  </ng-template> 
</mat-menu> 
<!--FILTERS SET-->
<ng-template #filterTest let-filterColumn="filterColumn" let-type="type" let-date="date" let-convertBoolean="convertBoolean">
    <ng-container *ngIf="type == 'dropdown'">
        <mat-selection-list *ngIf="filteredDataSet[filterColumn]" [(ngModel)]='filters[filterColumn]'
            (selectionChange)="onFilterChange()">
            <mat-list-option class="filtersTabs" checkboxPosition="before"
                *ngFor="let value of Array.from(filteredDataSet[filterColumn])" [value]="value">
                <ng-container *ngIf="convertBoolean !== undefined">
                    {{value == true ? "Yes" : "No" }}
                </ng-container>
                <ng-container *ngIf="convertBoolean === undefined">
                  {{date ? (value|datetimeFormat:"YYYY-MM-DD hh:mm:ss":"MM/DD/YYYY") : value }}
                </ng-container>
            </mat-list-option>
        </mat-selection-list>
    </ng-container>
    <ng-container *ngIf="type == 'search'">
        <div class='filters searchFilters' (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
            <input autocomplete="false" matInput placeholder="Search"
                (keyup.enter)="onSearch(filterColumn,filters[filterColumn])" [(ngModel)]="filters[filterColumn]" #input>
            <button mat-icon-button class='icon-holder' (click)="onSearch(filterColumn,filters[filterColumn])" matSuffix>
                <i class="material-icons">search</i>
            </button>
        </div>
    </ng-container>
    <ng-container *ngIf="type == 'datepicker'">
        <mat-calendar (click)="$event.stopPropagation()" [selected]="filters[filterColumn]"
            (selectedChange)="onFilterChange($event,filterColumn)"></mat-calendar>
    </ng-container>
    <ng-container *ngIf="type == 'range'">
        <div class="slider" (click)="$event.stopPropagation()" >
            <ngx-slider    [(value)]="sliderOptions[filterColumn].minValue" [(highValue)]="sliderOptions[filterColumn].maxValue" [options]="sliderOptions[filterColumn].options"  [manualRefresh]="manualRefresh"  ></ngx-slider>
        </div>
        <button class="apply-button"  mat-flat-button color="warn" (click)="applyRangeFilter($event,filterColumn,sliderOptions[filterColumn].minValue,sliderOptions[filterColumn].maxValue)">Apply</button>
    </ng-container>
</ng-template>