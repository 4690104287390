<div class="mat-dialog-title">
    <h1> {{ data.title }} </h1>
    <button mat-icon-button class="close-button" mat-dialog-close>
        <mat-icon *ngIf="!data.isCloseButtonHidden" class="close-icon" color="primary" (click)="close()">close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <p [innerHtml]="data.message" class="message"></p>
</div>
<div mat-dialog-actions class="action-buttons" align="end">
    <button *ngIf='data.type=="confirm"' class="cancel-button" mat-button mat-flat-button (click)="onDiaglogAction('cancel')" >{{data.buttonNegtiveText?data.buttonNegtiveText:'Cancel'}}</button>
    <button mat-button mat-flat-button color='warn' (click)="onDiaglogAction('ok')" cdkFocusInitial>{{data.buttonPositiveText?data.buttonPositiveText:'OK'}}</button>
  </div>
  
