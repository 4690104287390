import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private isLoading = new BehaviorSubject<any>(false);
  // isLoading = new Subject<boolean>();

  showLoader() {
    this.isLoading.next(true);
  }
  fetchLoader() {
    return this.isLoading.asObservable();
  }
  hideLoader() {
      this.isLoading.next(false);
  }
  constructor() { }
}
