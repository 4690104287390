import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class BannerService {
  private _subject = new BehaviorSubject<any>([]);
 
  getObservable(): Observable<any> {
    return this._subject.asObservable();
  }
  openAlert(data: any) {
    this._subject.next(data);
  }

  
}