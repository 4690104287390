import { RoleGuard } from './guards/role.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { roleAccessMapping } from './app.config';
import { SupportScreenGuard } from './guards/support-screen.guard';

const routes: Routes = [
  {
    path: 'login/callback',
    component: OktaCallbackComponent,
  },
  {
    path: '',
    redirectTo: 'orders',
    pathMatch: 'full',
    
  },
  {
    path: 'locations',
    loadChildren: () => import('./module/locations/locations.module').then((m) => m.LocationsModule),
    canActivate: [OktaAuthGuard,RoleGuard],
    data:roleAccessMapping.locations
  },
  {
    path: 'employees',
    loadChildren: () => import('./module/employees/employees.module').then((m) => m.EmployeesModule),
    canActivate: [OktaAuthGuard,RoleGuard],
    data:roleAccessMapping.employees
  },
  {
    path: 'orders',
    loadChildren: () => import('./module/orders/orders.module').then((m) => m.OrdersModule),
    canActivate: [OktaAuthGuard,RoleGuard],
    data:roleAccessMapping.orders
  },
  {
    path: 'monitor',
    loadChildren: () => import('./module/schedule-orders/schedule-orders.module').then((m) => m.ScheduleOrdersModule),
    canActivate: [OktaAuthGuard,RoleGuard],
    data:roleAccessMapping.monitor
  },
  {
    path: 'openOrders',
    loadChildren: () => import('./module/open-orders/open-orders.module').then((m) => m.OpenOrdersModule),
    canActivate: [OktaAuthGuard,RoleGuard],
    data:roleAccessMapping.openOrders
  },
  {
    path: 'exceptions',
    loadChildren: () => import('./module/exceptions/exceptions.module').then((m) => m.ExceptionsModule),
    canActivate: [OktaAuthGuard,RoleGuard],
    data:roleAccessMapping.exceptions
  },
  {
    path: 'holidays',
    loadChildren: () => import('./module/holidays/holidays.module').then((m) => m.HolidaysModule),
    canActivate: [OktaAuthGuard,RoleGuard],
    data:roleAccessMapping.holidays
  },
  {
    path: 'diagnostics',
    loadChildren: () => import('./module/diagnostics/diagnostics.module').then((m) => m.DiagnosticsModule),
    
  },
  {
    path: 'capacityCalculator',
    loadChildren: () => import('./module/capacity-calculator/capacity-calculator.module').then((m) => m.CapacityModule),
    canActivate: [OktaAuthGuard,RoleGuard],
    data:roleAccessMapping.capacityCalculator
  },
  {
    path: 'supervisor',
    loadChildren: () => import('./module/supervisor/supervisor.module').then((m) => m.SupervisorModule),
    canActivate: [OktaAuthGuard,RoleGuard],
    data:roleAccessMapping.supervisor
  },
  {
    path: 'glance',
    loadChildren: () => import('./module/work-at-glance/work-at-glance.module').then((m) => m.WorkAtGlanceModule),
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./module/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [OktaAuthGuard,RoleGuard],
    data:roleAccessMapping.admin
  },
  {
    path: 'contractorAppointment',
    loadChildren: () => import('./module/contactor-appointment/contactor-appointment.module').then((m) => m.ContactorAppointmentModule),
    canActivate: [OktaAuthGuard,RoleGuard],
    data:roleAccessMapping.contractorAppointment
  },
  {
    path: 'support',
    loadChildren: () => import('./module/support/support.module').then((m) => m.SupportModule),
    canActivate: [OktaAuthGuard,SupportScreenGuard],
    //data:roleAccessMapping.support
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
