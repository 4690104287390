import { AlertsComponent } from './../alerts/alerts.component';
import { Component, Input, OnInit, SimpleChanges, ViewChild, ViewEncapsulation, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { map } from 'lodash-es';
import * as APPCONFIG from '../../app.config';
import { UserService } from '../../services/user.service';
import { ToastService } from 'src/app/services/toast.service';
import { Hold, OrdersService, SoStatusUpdate } from 'src/app/services/orders.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import * as moment from 'moment';
import { HoldStatusAlertComponent } from '../hold-status-alert/hold-status-alert.component';
import { SharedService } from 'src/app/services/shared.service';
import { Router } from '@angular/router';
import { Options, LabelType } from "@angular-slider/ngx-slider";

interface ITableFilter {
  column: string;
  value: any;
}

@Component({
  selector: 'app-orders-grid',
  templateUrl: './orders-grid.component.html',
  styleUrls: ['./orders-grid.component.scss'],
})
export class OrdersGridComponent implements OnInit {
  Array = Array;
  sliderOptions={
    'balance':{
      minValue:1500,
      maxValue:6000,
      options: {
        floor: 0,
        ceil: 10000,
        animate:false,
        translate: (value: number, label: LabelType): string => {
          switch (label) {
            case LabelType.Low:
              return "Min: $" + value;
            case LabelType.High:
              return "Max: $" + value;
            default:
              return "$" + value;
          }
        }
      }
    },
    'bpemCounter':{
      minValue:0,
      maxValue:110,
      options: {
        floor: 0,
        ceil: 110,
        animate:false,
        // translate: (value: number, label: LabelType): string => {
        //   switch (label) {
        //     case LabelType.Low:
        //       return "Min: $" + value;
        //     case LabelType.High:
        //       return "Max: $" + value;
        //     default:
        //       return "$" + value;
        //   }
        // }
      }
    },
  }
  manualRefresh: EventEmitter<void> = new EventEmitter<void>();
  emitManualRefresh() {
    setTimeout(() => {
      this.manualRefresh.emit();
    }, 150);
  }
  displayedColumns: string[] = [];// = ['WO#','matcodeDescription', 'matCode',  'Due date', 'Balance (amount due)', 'state', 'district', 'address', 'zip', 'Meter size', 'MRU', 'locality', 'Crew size', 'Escort', 'escalator', 'meterLocation', 'priority', 'Early start date', 'Appt. date & time', 'Meter #', 'status', 'Assigned engineer', 'Created by User ID'];
  searchValue = this.ordersService.searchValue;
  selectedOrder = [];
  @Input() gridPageSize;
  balanceRange = ['less than 250$',  'Between 250$-1000$',  'Between 1000$-2500$', 'Greater than 2500$'];
  filters = {
    'matCode': [],
    'district':[],
    'priority':[],
    'status':[], 
    'dueDate':[],
    'matCodeDescription' :[],
    'balance':[],
    'state':[],
    'zip':[],
    'meterSize':[],
    'meterReadingUnit':[],
    'locality':[],
    'crewSize':[],
    'policeEscort':[],
    'escalator':[],
    'meterLocation':[],
    'earlyStartDate':[],
    'appointmentTimeSlot':[],
    'name':[],
    'createdBy':[],
    'creationDateTime':[],
    'creationTime':[],
    'woDesc':[],
    'houseAddress':[],
    'workOrderNumber':[],
    'longText':[],
    'city':[],
    'meterSerialNumber':[],
    'appointmentStartDate':[],
    "miu1EndPoint":[],
    "pinnedFlag":[],
    "meterInstallDate":[],
    "meterManufacturer":[],
    "bpemCounter":[],
    "landlordAllocation":[],
    "workCenter":[],
    "jeopardy":[],
    'premiseType':[],
    'specialHandling':[],
    'holidaySupressed':[]
  };
  filtersList : Object= {
    'pending':{},
    'monitor':{},
    'undated':{}
  };
  filteredDataSet = {};
  dataSource = new MatTableDataSource();
  dataOnCurrentPage = [];
  rowHeight = 32;
  currentPageIndex = 0;
  dragIcon = new Image();
  // clickedRows = new Set<PeriodicElement>()
  filterCount:number =0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("container", { read: ElementRef }) container: ElementRef;
  @Input() orders: any;
  @Input() VisibleColumns;
  @ViewChild("tabledata") tabledata;
  @Input() engineerModal;
  @Input() actionButtons;
  @Input() engineerData;
  @Input() readOnly=false;
  @Input () currentTab;
  newcolstr;
  restrictedfileds;
  columns = [];
  previousData;
  constructor(private router: Router,private ordersService: OrdersService, private toastService: ToastService, private loader: LoaderService, private _userService: UserService,private matDialog:MatDialog,private sharedService: SharedService) { }
  @Output() visibleOrders = new EventEmitter<any>();
  @Output() sendSelectedOrder = new EventEmitter<any>();
  @Output() selectedOrderList = new EventEmitter<any>();
  @Output() reloadData = new EventEmitter<any>();
  @Output() pageIndexChanged=new EventEmitter<any>();
  statusActionMapping=APPCONFIG.StatusActionMapping;
  menuTopLeftPosition =  {x: '0', y: '0'} 
  
  @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger; 
  @ViewChild('aboveMenu') aboveMenu: MatMenuTrigger;
  @ViewChild('menuTrigger') filterMenu: MatMenuTrigger;

  isDraggable = true;
  ngOnInit(): void {
  //  console.log(this.orders, "Table ORDERS data");
    this.gridPageSize = this.gridPageSize ? this.gridPageSize: 50
    this.newcolstr = APPCONFIG.pendingOrder_Columns;
    this.restrictedfileds = APPCONFIG.RestrictPendingColumns;
    this.loadSavedUserPreferences();
    this.dragIcon.src = '../assets/drag_icon.png';
    this.dragIcon.width = 40;
    this.dragIcon.height = 40;
    if(this.currentTab){
      this.isDraggable = false;
      this.persistDataFilters();
    }
  }

  ngAfterViewInit() {
    // debugger;
    this.setOrdersListOptions();
    // if(!this.engineerModal){
    // this.getDataOnCurrentPage();
    // }
  }

  

  ngOnChanges(changes: SimpleChanges) {
 // console.log("ngonchanges",this.filters,"--->>>filteredDataSet",this.filteredDataSet);
    this.searchValue = this.ordersService.searchValue
    if( this.orders.length === 1 && this.ordersService.searchValue){
      this.isDraggable = false;
    } else {
      this.isDraggable = true;
    }
    if (changes.orders) {
     // this.dataSource = new MatTableDataSource(this.orders);
      this.dataSource.data =  this.orders[0] && this.orders[0]["workOrderNumber"] ? this.orders : [];
      this.previousData = this.orders;
      this.getDataOnCurrentPage();
    }
    // debugger;
    if (changes.VisibleColumns) {
      if (changes.VisibleColumns.firstChange == false) {
        let arr = changes.VisibleColumns.currentValue.split(",");
        this.columns = arr.map(x => { return { name: x.split("-")[0], selected: JSON.parse(x.split("-")[1]), showAlways: false } });
        this.columns = this._userService.loadRestricted(this.columns);
        //console.log(this.columns);
        this.setDispalyedColumns();
      }
    }
    const selectedOrdersList = JSON.parse(localStorage.getItem("pendingOrderSelected")) ? JSON.parse(localStorage.getItem("pendingOrderSelected")) : null;
    if(selectedOrdersList?.length > 0) {
      this.selectedOrder = [...selectedOrdersList];
        let temp =this.selectedOrder.filter((selected,index)=>{
          let indexRow =  this.dataSource.data.findIndex(rank => rank['workOrderNumber'] === selected.workOrderNumber)
         if(indexRow >= 0){
          this.dataSource.data[indexRow]['active'] = true;
          return true
         } else {
           return false;
         }         
       })
       this.selectedOrder = temp;
       this.selectedOrderList.next(this.selectedOrder);
      //console.log(temp,"temp");
      }

  }
  persistDataFilters() {
    // console.log("persistDataFilters");
    
    let filters;
    let pagination;
    let filterSetTo;
   switch(this.currentTab){
     case 'pending':
       filterSetTo = JSON.parse(localStorage.getItem("filters")) ? JSON.parse(localStorage.getItem("filters")) : null;
       filters = filterSetTo ? filterSetTo['pending'] : filters;
       pagination= JSON.parse(localStorage.getItem("pagination")) ? JSON.parse(localStorage.getItem("pagination")) : null;
     break;
     case 'monitor':
       filterSetTo = JSON.parse(localStorage.getItem("filters")) ? JSON.parse(localStorage.getItem("filters")) : null;
       filters = filterSetTo ? filterSetTo['monitor'] : filters;
     break;
     case 'undated':
      filterSetTo = JSON.parse(localStorage.getItem("filters")) ? JSON.parse(localStorage.getItem("filters")) : null;
      filters = filterSetTo ? filterSetTo['undated'] : filters;
    break;
   }
  
      if(filters && Object.keys(filters).length > 0 ){
        this.filters = filters;
        this.onFilterChange()
      }
      // debugger;
      if(pagination?.pageIndex > 0){
        this.onPageChange(pagination);
      } else{
        this.onPageChange(null);
       
      }
    }

  setDispalyedColumns() {
    this.displayedColumns = this.columns.filter(x => { return x.selected == true }).map(y => { return y.name });
  }

  onScroll(event) {
    //console.log(event);
    if (event && event.srcElement) {
      const srcElement = event.srcElement;
      const startIndex = Math.round(srcElement.scrollTop / this.rowHeight);
      const itemsToFit = Math.ceil((srcElement.clientHeight - 55) / this.rowHeight);
      //console.log(this.dataOnCurrentPage);
      const visibleOrders = this.dataOnCurrentPage.slice(startIndex, startIndex + itemsToFit);
      // debugger;
    
        this.visibleOrders.emit(visibleOrders);

      
      //console.log(startIndex, itemsToFit);
    }
  }

  setOrdersListOptions() {
   // console.log('setOrdersListOptions',this.sort);
    this.dataSource.filterPredicate = this.customFilterPredicate;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortData = (data: any, sort: MatSort) => {
     // console.log('Sort', sort);
      const active = sort.active;
      const direction = sort.direction;
      if (!active || direction == '') {
        //const applyDefaultSortedData = this.sharedService.defaultSortWorkOrder(data,this.currentTab);
        //return applyDefaultSortedData;
      }

      const sorted = data.sort((a, b) => {
        let valueA = a[active];
        let valueB = b[active];

        let comparatorResult = 0;
        if (valueA != null && valueB != null) {
          if (valueA > valueB) {
            comparatorResult = 1;
          } else if (valueA < valueB) {
            comparatorResult = -1;
          }
        } else if (valueA != null) {
          comparatorResult = 1;
        } else if (valueB != null) {
          comparatorResult = -1;
        }

        return comparatorResult * (direction == 'asc' ? 1 : -1);
      });

   //   console.log(sorted)
      return sorted;
    };
  }


  // distinctValue() {
  //   map(this.orders, item => {
  //     Object.entries(item).forEach(([key, value]) => {
  //       if (this.filteredValues[key]) {
  //         this.filteredValues[key].add(value);
  //       }
  //     });
  //   });
  // // console.log(this.filteredValues,"FilteredValues");
  // }
  
  onFilterChange(event?,filterColumn?) {
    this.clearSelection();
    if(filterColumn){
      this.filters[filterColumn] = moment(event).format('YYYY-MM-DD') 
    }
    window['event']?.stopPropagation();
    this.searchValue = null;
    const filters = [];
    let tabCount = 0;
    console.log(this.filters,"filters");
    const pagination = JSON.parse(localStorage.getItem("pagination")) && this.currentTab === 'pending' ? JSON.parse(localStorage.getItem("pagination")) : null;
    Object.keys(this.filters).forEach(columnName => {
      const filter = this.filters[columnName];
      if (filter.length) {
        filters.push({ column: columnName, value: filter });
        if((columnName=="balance"||columnName=="bpemCounter") && this.filters[columnName].length>0){
          this.sliderOptions[columnName].minValue = this.filters[columnName][0];
          this.sliderOptions[columnName].maxValue = this.filters[columnName][1];
        }
      }
        if(filter.length>0){
         tabCount = tabCount + 1;
      }
    });
     //console.log(tabCount,"----------------TabCount----------------");
     this.filterCount = tabCount
    const filter: ITableFilter | any = filters;
    this.dataSource.filter = filter;
    if (this.dataSource.paginator && pagination?.pageIndex == 0) {
      this.dataSource.paginator.firstPage();
      this.currentPageIndex = 0;
    }
    this.filtersList = JSON.parse(localStorage.getItem("filters")) ? JSON.parse(localStorage.getItem("filters")) : this.filtersList;
    switch(this.currentTab){
      case 'pending':
        this.filtersList['pending'] = this.filters
        localStorage.setItem('filters', JSON.stringify(this.filtersList));
      break;
      case 'monitor':
        this.filtersList['monitor'] = this.filters;
        localStorage.setItem('filters', JSON.stringify(this.filtersList));
      break
      case 'undated':
        this.filtersList['undated'] = this.filters;
        localStorage.setItem('filters', JSON.stringify(this.filtersList));
        break
    }
    this.getDataOnCurrentPage(true);
  }


  onClearFilter() {
    this.sort.sort({id: '', start: 'asc', disableClear: false});
    this.isDraggable = this.readOnly ? false : true;
    this.filteredDataSet = {}
    this.filters = {
      'matCode': [],
      'district':[],
      'priority':[],
      'status':[], 
      'dueDate':[],
      'matCodeDescription' :[],
      'balance':[],
      'state':[],
      'zip':[],
      'meterSize':[],
      'meterReadingUnit':[],
      'locality':[],
      'crewSize':[],
      'policeEscort':[],
      'escalator':[],
      'meterLocation':[],
      'earlyStartDate':[],
      'appointmentTimeSlot':[],
      'name':[],
      'createdBy':[],
      'creationDateTime':[],
      'creationTime':[],
      'woDesc':[],
      'houseAddress':[],
      'workOrderNumber':[],
      'longText':[],
      'city':[],
      'meterSerialNumber':[],
      'appointmentStartDate':[],
      'miu1EndPoint':[],
      'pinnedFlag':[],
      'meterInstallDate':[],
      'meterManufacturer':[],
      'bpemCounter':[],
      "landlordAllocation":[],
      'workCenter':[],
      'jeopardy':[],
      'premiseType':[],
      'specialHandling':[],
      'holidaySupressed':[]
      
    };
    this.filterCount = 0
    this.dataSource.data = this.previousData;
    this.dataSource.data.map(item=>{
      if(item['active']){
        item['active'] = false;
      }
    })
    this.filtersList = JSON.parse(localStorage.getItem("filters")) ? JSON.parse(localStorage.getItem("filters")) : this.filtersList;
    switch(this.currentTab){
      case 'pending':
        this.filtersList['pending'] = {};
        localStorage.setItem('filters', JSON.stringify(this.filtersList));
        localStorage.removeItem("pagination");
      break;
      case 'monitor':
        this.filtersList['monitor'] = {};
        localStorage.setItem('filters', JSON.stringify(this.filtersList));
      break;
      case 'undated':
        this.filtersList['undated'] = {};
        localStorage.setItem('filters', JSON.stringify(this.filtersList));
      break
    }
 
    
    if (this.dataSource && this.dataSource.filter && this.dataSource.filter.length > 0) {
      this.dataSource.filter = null;
    }
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
      this.currentPageIndex = 0;
    }
    this.selectedOrder = [];
    this.selectedOrderList.next(this.selectedOrder);
    this.getDataOnCurrentPage(true);
    if(this.ordersService.searchValue){
      this.searchValue = null;
      this.ordersService.searchValue = null;
      this.reloadData.emit()
    } 
    localStorage.removeItem("pendingOrderSelected");
  }

  customFilterPredicate(data: any, filters: any,type?:string): boolean {
    let balanceValues = {'less than 250$' : {min: 0 ,max: 250},'Between 250$-1000$':{ min: 250, max: 1000}, 'Between 1000$-2500$':{ min: 1000, max: 2500},'Greater than 2500$': { min: 2500, max: null}}
    const searchInput = ['workOrderNumber','longText','houseAddress','meterSerialNumber','meterReadingUnit','createdBy'];
    const range = ['balance','bpemCounter'];
    if (!Array.isArray(filters)) {
      const dataStr = Object.values(data).join('◬').toLowerCase();
      const transformedFilter = filters ? filters.trim().toLowerCase() : '';
      return dataStr.indexOf(transformedFilter) != -1;
    } else {
      for (let i = 0; i < filters.length; i++) {
        if (filters[i].value.indexOf(data[filters[i].column]) === -1  ) {
          if(searchInput.indexOf(filters[i].column)>=0 && data[filters[i].column] && filters[i].value.length > 0 && data[filters[i].column].toLowerCase().search(filters[i].value[0].toLowerCase()) >= 0){
            //console.log(data[filters[i].column],"true",filters[i].value);
            return true;
          } else if(range.indexOf(filters[i].column)>=0){
            if((parseInt(data[filters[i].column]) >= filters[i].value[0] && (parseInt(data[filters[i].column]) <= filters[i].value[1]  ))){
              return true
            } else {return false}
          }else {
            return false;
          }
        } 
      }
      return true;
    }
  }


  onSortChange(e) {
    this.getDataOnCurrentPage();
  }

  onPageChange(e) {
  // console.log(e);
   if(e==null){
    this.currentPageIndex = 0;
    this.getDataOnCurrentPage();
    this.pageIndexChanged.emit(0);
   }
   else{
    if(this.currentTab === 'pending'){
      localStorage.setItem('pagination', JSON.stringify(e));
     }
      this.currentPageIndex = e.pageIndex;
      this.getDataOnCurrentPage();
      this.pageIndexChanged.emit(e.pageIndex);
   }
 
  }

  getDataOnCurrentPage(clear?) {
    setTimeout(() => {
      this.dataOnCurrentPage = this.dataSource['_renderData']._value;
      let i = 1;
      this.dataOnCurrentPage = map(this.dataOnCurrentPage, item => {
        item.index = (this.currentPageIndex * this.gridPageSize) + i++;
        return item;
      });
      const itemsToFit = Math.ceil((this.container.nativeElement.clientHeight - 55) / this.rowHeight);
      const visibleOrders = this.dataOnCurrentPage.slice(0, itemsToFit);
      // debugger;
   if(this.filterCount > 0){
    this.visibleOrders.emit({order:this.dataSource['_renderData']._value,count:this.filterCount});

   }else if(clear){
    this.visibleOrders.emit({order:this.dataSource['_renderData']._value,clear:clear});
   }else {
    this.visibleOrders.emit(visibleOrders);
   }

      
     // console.log(this.dataOnCurrentPage);
    }, 500);
  }
  onSelectRow(row) {
    // debugger;
    if(this.currentTab === 'pending' || this.actionButtons){
    let deleteRow = this.selectedOrder.findIndex(rank => rank.workOrderNumber === row.workOrderNumber);

    if (deleteRow >= 0) {
      this.selectedOrder[deleteRow].isSelectedOrder = false;
      this.selectedOrder.splice(deleteRow,1);
      row.active = false;
      row.isSelectedOrder = false;
      // this.sendSelectedOrder.emit({});
    } else {
      //this.selectedOrder.clear();
      const elementPos = this.dataSource['_renderData']._value.map(x => x['workOrderNumber']).indexOf(row.workOrderNumber);
      if (elementPos !== -1) {
        row.index = (this.currentPageIndex * this.gridPageSize) + elementPos + 1;
      }
      row.isSelectedOrder = true;
      this.selectedOrder.push(row);
      
    }
    if(this.currentTab){
      this.selectedOrderList.next(this.selectedOrder);
    } else {
      this.ordersService.addselectedOrders(this.selectedOrder)
    }
    //this.selectedOrderList(this.selectedOrder);

   // console.log(this.selectedOrder, "selected Orders");
    // }
  }
  }
  selectAllRows() {
    const unSelectableOrderStatusList = ['CANC', 'INCM', 'COMP'];
    const renderedOrders = this.dataSource['_renderData']._value.filter(row => !unSelectableOrderStatusList.includes(row.status));
    renderedOrders.forEach(row=> {
      const elementPos = this.dataSource['_renderData']._value.map(x => x['workOrderNumber']).indexOf(row.workOrderNumber);
      if (elementPos !== -1) {
        row.index = (this.currentPageIndex * this.gridPageSize) + elementPos + 1;
      }
      row.selected = true;
      row.isSelectedOrder = true;
    });
    this.selectedOrder.splice(0, this.selectedOrder.length); // clear manual selections
    this.selectedOrder.push(...renderedOrders);
    if(this.currentTab){
      this.selectedOrderList.next(this.selectedOrder);
    } else {
      this.ordersService.addselectedOrders(this.selectedOrder)
    }
  }
  deSelectAllRows() {
    this.dataSource['_renderData']._value.forEach(row => {
      row.active = false;
      row.isSelectedOrder = false;
    });
    this.selectedOrder = [];
    if(this.currentTab){
      this.selectedOrderList.next(this.selectedOrder);
    } else {
      this.ordersService.addselectedOrders(this.selectedOrder)
    }
  }

  clearSelection() {
    this.selectedOrder.forEach(row => {
      row.active = false;
      row.isSelectedOrder = false;
    });
    this.selectedOrder = [];
    if(this.currentTab){
      this.selectedOrderList.next(this.selectedOrder);
    } else {
      this.ordersService.addselectedOrders(this.selectedOrder)
    }
  }

  drop(event: CdkDragDrop<string[]>) {
   if(event.previousIndex !== event.currentIndex && event.currentIndex !== 0  && event.previousIndex !== 0){
     moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
     this.saveColumnReorder(this.displayedColumns.map(x => { return x + "-true" }));
   }
  }

  saveColumnReorder(columnList) {
    // debugger;
    let cols = this.columns.filter(x => { return x.selected == false }).map(y => { return y.name + "-false" });
    columnList = [...columnList, ...cols];
    let newatr = columnList.join(",");
    this.loader.showLoader();
    this._userService.saveUserPreferences(this._userService.getEmployeeId(), newatr).subscribe(res => {
      if (res["success"]) {
        this.toastService.success("Column Reorder Preferences Saved Successfully");
      }
      else {
        this.toastService.error("Could not save the user settings");
      }
      this.loader.hideLoader();
    }, error => {
      console.log("error in saving column reorder", error);
    });
  }

  loadSavedUserPreferences() {
    let employeeid = this._userService.getEmployeeId();
    let self = this;
    this.loader.showLoader();
    this._userService.getUserPreferences(employeeid).subscribe(data => {
      // debugger;
      if (data == null) {
        self.columns = self._userService.loadDefaultData();
      }
      else {
        let dbData = data["userPreference"];
        let arr = dbData.split(",");
        self.columns = arr.map(x => { return { name: x.split("-")[0], selected: JSON.parse(x.split("-")[1]), showAlways: false } });
      }
      self.columns = self._userService.loadRestricted(self.columns);
      self.setDispalyedColumns();
      self.loader.hideLoader();
    },
      error => {
        console.log("Error in fetching user preferences", error);
      })
  }
  handleDragStart(event, data) {

    var dragSrcEl = event.target;
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setDragImage(this.dragIcon, 30, 34);
    var item = {
      type: 'range',
      content: data["workOrderNumber"],
      id: data["workOrderNumber"],
      data: data
    };


    event.dataTransfer.setData("text", JSON.stringify(item));
  }
  showComments(message){
    const alertObject = {
      type: 'alert',
      title: 'Comments',
      message: message,
      
    };
    this.matDialog.open(AlertsComponent, { data: alertObject, hasBackdrop: true, disableClose: true,maxWidth:"32vw",minWidth: '25vw'});
  }
  /**
   * right click on Single Engineer View to Hold work orders
   * @param event Mouse Event clicked
   * @param row row workorder selected on right click
   */
  onRightClick(event: MouseEvent, row) { 
    event.preventDefault(); 
    this.menuTopLeftPosition.x = event.clientX + 'px'; 
    this.menuTopLeftPosition.y = event.clientY + 'px'; 
    // we open the menu 
    this.matMenuTrigger.menuData = {item: row} 
    this.matMenuTrigger.openMenu(); 
  } 


  holdWorkOrder(orderDetails,hold){
    let accessToken = JSON.parse(window.localStorage['okta-token-storage']);
    let assignedEngineer ;
    switch(this.currentTab){
      case 'monitor':
         assignedEngineer = orderDetails?.mappingPersonnelNumber?orderDetails.mappingPersonnelNumber.toString():orderDetails.assignedTo.toString()
      break;
      case 'search':
         assignedEngineer = orderDetails?.mappingPersonnelNumber?orderDetails.mappingPersonnelNumber.toString():orderDetails.assignedTo.toString()
      break;
      default:
         assignedEngineer = this.engineerData?.mappingPersonnelNumber?this.engineerData.mappingPersonnelNumber.toString():this.engineerData.personnelNumber.toString()
      break;
    }
    this.loader.showLoader();
          let holdObject:Hold = {
          "assignedEngineer":assignedEngineer, 
          "assignmentEnd": orderDetails.assignmentEnd ? moment(orderDetails.assignmentEnd).format('YYYY-MM-DDHH:mm:ss'): null,
          "assignmentStart": orderDetails.assignmentStart ? moment(orderDetails.assignmentStart).format('YYYY-MM-DDHH:mm:ss'):null,
          "dispatchId": accessToken.idToken.claims.preferred_username.split('@')[0],
          "engineerId": this.engineerData ? this.engineerData?.userId : orderDetails.userId,
          "itemTimeStamp": moment().format("YYYY-MM-DDHH:mm:ss"),
          "operationNumber": "10",
          "workOrderNumber": orderDetails.workOrderNumber,
          "statusNonNumber":"",
          "statusNotes":hold.reason[0]=="Other"? hold.otherReason: hold['reason'][0],
          "statusNumber":"HOLD"
          };
          //console.log(moment(orderDetails.assignmentStart).isAfter(new Date()));
          //console.log([holdObject],"holdObject");
          this.ordersService.holdWorkOrders([holdObject]).subscribe((response)=>{
            this.loader.hideLoader();
            console.log(response);
            let success = []
        let failed = []
        for( let key in response) {
           console.log(key);
          if (response[key]) {
            success.push(key);
          } else {
            failed.push(key);
          }
        };
        
            if(success.length > 0){
              if(this.currentTab == 'search'){
                this.reloadData.emit();
              }
              orderDetails.status = "HOLD";
              this.toastService.success('The workorder('+success.join()+") was successfully put on hold");
            }
            if(failed.length>0){
              this.toastService.error("Could not put on hold workorder("+failed.join()+") Please try again");
            }
          },(error)=>{
            this.loader.hideLoader();
            this.toastService.error("There was an error Please try again later");
          });
  }
  

applyFilter(searchColumnKey,filterValue) {
  const tableFilters = [];
  tableFilters.push({
    column: searchColumnKey,
    value: filterValue ? filterValue : []
  });
  const filter: ITableFilter | any = tableFilters;

  this.dataSource.filter = filter;
  this.onFilterChange()
}
onSearch(key,value){
  this.filters[key] =[]
  value = value.toString()
  if(value?.trim() !== ''){
    this.filters[key].push(value);
  }
  this.applyFilter(key,value);
}
ngOnDestroy() {
  if(this.currentTab){
    localStorage.removeItem("pendingOrderSelected");
  }
}
onClickFilter(key1) {
  //console.log(this.filteredDataSet, "Initial filteredDataSet");
  
  if(key1=='balance'||key1=='bpemCounter'){
    this.emitManualRefresh()
  }
  map(this.orders, item => {
    Object.entries(item).forEach(([key, value]) => {
      if (key == key1) {
        if (this.filteredDataSet[key]) {
          this.filteredDataSet[key] = new Set(this.filteredDataSet[key]); 
            this.filteredDataSet[key].add(value);
        } else {
          this.filteredDataSet[key] = new Set();
          this.filteredDataSet[key].add(value);
        }
      }
    });
  });
    this.filteredDataSet[key1] = Array.from(this.filteredDataSet[key1]).sort();
}
isRowSeleted(array,row){
  return array.some(x=>x.workOrderNumber===row.workOrderNumber);
}
clearRadio(columnName){
  this.filters[columnName] = []
  this.onFilterChange()
}

rightMenuActions(event,action,data){
  switch(action){
    case 'copy':
      this.toastService.success("copied!");
      break;
    case 'audit':
      this.sharedService.openAuditLogsModal(data)
      break;
      case 'workAtAGlance':
        // const link = this.router.serializeUrl(this.router.createUrlTree(['/glance', { workOrder: data }]));
        // window.open(link, '_blank');
        this.matDialog.closeAll();
         this.router.navigate(['/glance'],{queryParams: { workOrder: data }});
        // let url=APPCONFIG.API_URL.workAtAGlance.replace("{wonumber}",data); 
        // window.open(url,'_blank');
      break;
      case 'putOnHold':
        const alertObject = {
          title: 'Reason',
          button:'Put on Hold',
          list : ["Working Emergency","Assisting other FSRs","Truck Maintenance","Lunch","Customer Unavailable","System Issue","Need Assistance Completing","Gave Time to Pay","Move to Other FSR","Working Other Order","Working MapCall Order","2 Person Job","Other"]
        };
        const dialog =this.matDialog.open(HoldStatusAlertComponent, { data: alertObject, hasBackdrop: true, disableClose: true});
        dialog.afterClosed().subscribe((result) => {
          if (result) {
            //console.log(unscOrdersList,"unscOrdersList");
            this.holdWorkOrder(data,result);
          }
        });
      break
    
  }
}
showCompletionNotes(wono){
this.loader.showLoader();
this.ordersService.getCompletionNotes(wono).subscribe((response:any)=>{
  this.loader.hideLoader();
  if(response.success){
  const alertObject = {
    type: 'alert',
    title: 'Completion Notes',
    message: response.statusText.replace(new RegExp('\n', 'g'), "<br />"),
  };
  this.matDialog.open(AlertsComponent, { data: alertObject, hasBackdrop: true, disableClose: true,});
  }
  else{
    this.toastService.info("No Completion Notes available for this order");
  }
},(error)=>
{
  this.loader.hideLoader();
  this.toastService.error("There was an error Please try again later");
  console.log(error);
});
}


applyRangeFilter($event,key,minval,maxval){
  console.log(key,minval,maxval,this.filters);
  this.filters[key] = [];
  this.filters[key].push(minval);
  this.filters[key].push(maxval);
  this.applyFilter(key,this.filters[key]);
  this.filterMenu.closeMenu();
}
}
