<div class="expansion-wrapper">
    <div class="selectAll search">
      <input type="text" placeholder="Search States & Districts" [(ngModel)]="searchValue" matInput>
    </div>
    <div *ngIf="!searchValue && mode=='multi'" class="selectAll">
      <h4>Select All</h4>
      <mat-checkbox class="custom-check"  [checked]="allDistrictsCount==outputArray.length" (change)="onSelectAllChange($event.checked)" labelPosition="before">
      </mat-checkbox>
    </div>
    <!--Expansion Group-->
    <mat-expansion-panel class="expansion-panel mat-elevation-z0" hideToggle *ngFor="let state of locationInfo|search:searchValue:'searchString'">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>keyboard_arrow_right</mat-icon>{{ state.state }}
        </mat-panel-title>
        <mat-checkbox class="custom-check" *ngIf="!searchValue && mode=='multi'" (change)="onStateChange($event.checked, state,true)"
          (click)="$event.stopPropagation()" [id]="state.state" [checked]="state.selectedDistrcitsCount==state.districts.length"
          labelPosition="before"
          >
          <h5 *ngIf="state.selectedDistrcitsCount>0">{{state.selectedDistrcitsCount==state.districts.length?(state.state=='Contractor'?"All companies":"All districts"):state.selectedDistrcitsCount+(state.state=='Contractor'?" companies":" districts")}}</h5>
        </mat-checkbox>
        <h5 *ngIf=" selectedState.state==state.state && selectedState.district">{{selectedState.district.split("-@-")[0]}}</h5>
        <i *ngIf="selectedState.state==state.state" class="material-icons">check</i>
      </mat-expansion-panel-header>
      <!--Inner Expansion list-->
      <mat-list role="list" class="district-list">
        <mat-list-item class="district" role="listitem" [ngClass]="{'selected':selectedDistrict==district}"(click)="onSingleDistrict(state,district)" *ngFor="let district of state.districts|search:searchValue:'searchString'">
         <p class="indent"> {{ district.name.split("-@-")[0] }}</p>
          <mat-checkbox *ngIf="mode=='multi'" class="custom-check" [checked]="district.selected" (change)="onDistrictChange(state,district,true)"
            [id]="district.name" labelPosition="before">
            
          </mat-checkbox>
          
          <i *ngIf="selectedDistrict==district" class="material-icons">check</i>
          <mat-divider></mat-divider>
        </mat-list-item>
        
      </mat-list>
    </mat-expansion-panel>
</div>