<div class="notifications">
    <div *ngFor="let banner of notifications; let i = index" class="notification"
        [ngClass]="getClassName(banner)">
        <ng-container *ngTemplateOutlet="notificationTpl; context:{banner:banner, index: i};">
        </ng-container>
    </div>
</div>
<ng-template #notificationTpl let-banner="banner" let-index="index">
    <div class="notification-content">
        <img src="/assets/icons/{{getIconName(banner)}}.svg" alt="">
        <div class="contents">
            <div class="title">
                <div class="title-container">
                    <span class="banner-title">
                        {{banner.title}}
                    </span>
                </div>
                <span mat-icon-button (click)="close(banner, index)" matTooltip="Close the notification"
                    matTooltipPosition="before">
                    <mat-icon class="close-btn">close</mat-icon>
                </span>
            </div>
            <div class="message" [innerHTML]="banner.showMore ? banner?.message : banner?.message">
                <!-- <div>
                    {{ notification.showMore ? notification.message : (notification.message | slice:0: textCounter)
                    +'...' }}
                </div> -->
            </div>
            <!-- <span [ngClass]="{'d-block': banner.showMore, 'd-flex-right': !banner.showMore}"
                class="showmoreText" (click)="toggleShowText(index)">
                {{banner.showMore ? 'Hide Details' : 'Show More'}}
            </span> -->
        </div>
    </div>
</ng-template>