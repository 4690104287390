import { OrdersGridComponent } from './../../components/orders-grid/orders-grid.component';
import { StateDistrictSelectorComponent } from './../../components/state-district-selector/state-district-selector.component';
// import { ValidatorStateDistrictSelectorComponent } from 'src/app/components/validator-state-district-selector/state-district-selector.component';

import { SearchPipe } from './../../pipes/search.pipe';
import { NgModule } from '@angular/core';
import { AppointmentWindowComponent } from 'src/app/components/appointment-window/appointment-window.component';
import { TimePickerComponent } from 'src/app/components/time-picker/time-picker.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSelectModule} from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatMenuModule} from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSortModule} from '@angular/material/sort';
import {MatSliderModule} from '@angular/material/slider';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { DatetimeFormatPipe } from './../../pipes/datetime-format.pipe';
import {MatPaginatorModule} from '@angular/material/paginator';
import { DayFormatPipe } from 'src/app/pipes/day-format.pipe';
import {MatBadgeModule} from '@angular/material/badge';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatRadioModule} from '@angular/material/radio';
import {MatChipsModule} from '@angular/material/chips';
import {MatCardModule} from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {UserSettingsComponent} from '../../components/user-settings/user-settings.component';
import { OrdersTimelineComponent } from 'src/app/components/orders-timeline/orders-timeline.component';
import { SingleEngineerModalComponent } from 'src/app/components/single-engineer-modal/single-engineer-modal.component';
import {NotesPopupComponent} from 'src/app/components/notes-popup/notes-popup.component';
import { WorkOrderDetailsComponent } from 'src/app/components/work-order-details/work-order-details.component';
import {LocationStatePanelComponent} from '../../components/location-state-panel/location-state-panel.component';
import { HoldStatusAlertComponent } from 'src/app/components/hold-status-alert/hold-status-alert.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { EsriMapComponent } from 'src/app/components/esri-map/esri-map.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { AppointmentModalComponent } from 'src/app/components/appointment-modal/appointment-modal.component';
import { MiuValidatorComponent } from 'src/app/components/miu-validator/miu-validator.component';
import { AlertsComponent } from 'src/app/components/alerts/alerts.component';

@NgModule({
  imports: [MatSidenavModule, MatTabsModule, MatRadioModule,MatSliderModule,MatRippleModule,MatBadgeModule,CommonModule,NgxSliderModule, MatIconModule, DragDropModule, MatSlideToggleModule,MatButtonModule,MatCheckboxModule,FormsModule, ReactiveFormsModule,MatExpansionModule,MatListModule,NgxMatTimepickerModule, MatDialogModule,MatTableModule,MatMenuModule,MatPaginatorModule,MatDatepickerModule,MatNativeDateModule,MatFormFieldModule,MatInputModule,MatSelectModule,MatTooltipModule,MatSortModule,ClipboardModule],
  declarations: [ MiuValidatorComponent, EsriMapComponent,AppointmentWindowComponent, TimePickerComponent,DatetimeFormatPipe,AlertsComponent,DayFormatPipe,SearchPipe,StateDistrictSelectorComponent, UserSettingsComponent,OrdersGridComponent,OrdersTimelineComponent,SingleEngineerModalComponent,NotesPopupComponent,WorkOrderDetailsComponent,LocationStatePanelComponent,HoldStatusAlertComponent,AppointmentModalComponent],
   
  exports: [
    MatRippleModule,
    MatBadgeModule,
    AppointmentWindowComponent, TimePickerComponent,StateDistrictSelectorComponent,EsriMapComponent,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatSelectModule,
    MatButtonModule,
    DragDropModule,
    NgxSliderModule,
    MatInputModule,
    MatTableModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSliderModule,
    MatSortModule,
    MatDividerModule,
    MatListModule,
    MatAutocompleteModule,
    FormsModule, ReactiveFormsModule,
    MatExpansionModule,
    DatetimeFormatPipe,
    MatPaginatorModule,
    DayFormatPipe,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatChipsModule,
    SearchPipe,
    MatCardModule,
    MatGridListModule,
    MatProgressBarModule,
    NgxMatTimepickerModule,
    MatTabsModule,
    OrdersGridComponent,
    OrdersTimelineComponent,
    SingleEngineerModalComponent,
    NotesPopupComponent,
    WorkOrderDetailsComponent,
    MatTooltipModule,
    LocationStatePanelComponent,
    ClipboardModule,
    AppointmentModalComponent,
  ]
})
export class SharedModule { }
