import { SharedService } from './services/shared.service';
import { Component } from '@angular/core';
import { LoaderService } from './services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isLoading;
  constructor(private sharedService: SharedService, private loader: LoaderService) {
  }
  ngOnInit() {
    this.loader.fetchLoader().subscribe(loader => {
      setTimeout(() => {
        this.isLoading = loader;
      }, 0);
    });
  }
}
